<template>
  <div ref="elRef" class="df-menu">
    <div ref="wrapRef" class="flex flex-nowrap gap-2">
      <slot></slot>
    </div>
    <Teleport to="body" :disabled="!mounted">
      <div class="absolute left-0 top-0 h-0 w-0">
        <Transition name="drawer" :duration="200">
          <div
            v-show="isShow('drawer')"
            class="df-menu__drawer"
            :style="{
              top: `${top}px`,
              height: `calc(100vh - ${top}px)`,
            }"
          >
            <div ref="detailRef" class="df-menu__detail" :style="{ paddingTop: `${offset}px` }">
              <div class="df-menu__content-wrap">
                <ItemsContent type="drawer" />
              </div>
            </div>
            <div class="df-menu__mask"></div>
          </div>
        </Transition>
        <Transition name="fade">
          <div
            v-show="isShow('dropdown')"
            ref="dropdownRef"
            class="df-menu__dropdown"
            :style="{
              top: `${top + offset}px`,
              left: `${left}px`,
            }"
          >
            <ItemsContent type="dropdown" />
          </div>
        </Transition>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { isNil } from 'lodash-es';
import { vShow, withDirectives, type VNode } from 'vue';
import { DF_MENU_CTX_KEY, type DfMenuItemInstance } from './types';

const props = defineProps({
  offset: { type: Number, default: 0 },
});

const activeKey = defineModel('active', {
  type: String,
  default: '',
});
const openKey = defineModel('open', {
  type: String,
  default: '',
});

const elRef = shallowRef<HTMLElement>();
const wrapRef = shallowRef<HTMLElement>();
const detailRef = shallowRef<HTMLElement>();
const dropdownRef = shallowRef<HTMLElement>();

const ItemsContent = (props: { type: string }) => {
  const { type } = props;
  return Object.keys(items)
    .filter((name) => !isNil(items[name]?.content) && items[name]?.type === type)
    .map((name) => {
      return withDirectives(h('div', {}, items[name]?.content as VNode), [[vShow, openKey.value === name]]);
    });
};

const top = ref(0);
const left = ref(0);
const offset = computed(() => items[openKey.value]?.offset || props.offset);
const isShow = (type: string) => {
  return !isNil(items[openKey.value]?.content) && items[openKey.value]?.type === type;
};

const items = shallowReactive<Record<string, DfMenuItemInstance>>({});

const global = useGlobalThis();
const { exec, cancel } = useTimeout();
useEventListener(global, 'mouseover', (ev: MouseEvent) => {
  const el = ev.target as HTMLElement;
  if (
    openKey.value === '' ||
    wrapRef.value?.contains(el) ||
    detailRef.value?.contains(el) ||
    dropdownRef.value?.contains(el) ||
    items[openKey.value]?.el?.contains(el)
  ) {
    if (items[openKey.value]?.type === 'dropdown') {
      cancel();
    }
    return;
  }
  if (items[openKey.value]?.type === 'dropdown') {
    exec(() => {
      openKey.value = '';
    }, 200);
    return;
  } else {
    openKey.value = '';
  }
});

watch(openKey, async (newVal, oldVal) => {
  if (newVal === '' || newVal === oldVal || isNil(items[newVal]?.el)) {
    return;
  }
  const itemEl: HTMLElement = items[newVal].el;
  const rect = itemEl.getBoundingClientRect();
  left.value = rect.left;

  if (items[newVal]?.type !== 'dropdown') {
    return;
  }

  await nextTick();
  const x = rect.left + (dropdownRef.value?.scrollWidth ?? 0) + 64;
  if (window.innerWidth < x) {
    const offset = x - window.innerWidth;
    console.log(left.value, rect.left, dropdownRef.value?.scrollWidth, x, offset);
    left.value = left.value - offset;
  }
});

const mounted = ref(false);
onMounted(() => {
  mounted.value = true;
  if (!isNil(elRef.value)) {
    const rect = elRef.value.getBoundingClientRect();
    top.value = rect.bottom;
  }
});

const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    openKey.value = '';
  },
);

const timeout = useTimeout();

provide(DF_MENU_CTX_KEY, {
  activeKey,
  openKey,
  items,
  timeout,
});
</script>

<style scoped lang="less">
.df-menu {
  position: relative;
}
.df-menu__drawer {
  position: fixed;
  left: 0;
  z-index: 1001;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: transparent;

  .df-menu__detail {
    width: 100%;

    .df-menu__content-wrap {
      //border-top: 1px solid #e7e7e7;
      padding: 0 40px 24px;
      background: #fff;
    }
  }
  .df-menu__mask {
    flex: 1;
    width: 100%;
    backdrop-filter: blur(1rem);
    background: rgba(0, 0, 0, 0.4);
  }

  &.drawer-enter-from,
  &.drawer-leave-to {
    .df-menu__mask {
      opacity: 0;
    }
    .df-menu__detail {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  &.drawer-enter-active,
  &.drawer-leave-active {
    .df-menu__mask {
      transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .df-menu__detail {
      transition:
        opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
.df-menu__dropdown {
  position: fixed;
  z-index: 1001;

  //transform: translate(-50%, 0);
}
</style>

<template>
  <div class="df-nav ml-[16px]">
    <DfMenu v-model:active="active" :offset="14">
      <DfMenuItem
        v-for="nav in navList"
        :key="nav.key"
        :type="nav.type"
        :name="nav.key"
        :title="nav.label"
        :offset="nav.offset"
        :to="nav.path"
        @click.prevent="myNavigateTo(nav.path ?? '', nav.label)"
      >
        <template v-if="hasSub(nav)" #detail>
          <Drawer v-if="nav.type === 'drawer'" :nav="nav" />
          <Dropdown v-if="nav.type === 'dropdown'" :nav="nav" />
        </template>
      </DfMenuItem>
    </DfMenu>
  </div>
</template>
<script setup lang="ts">
import { isEmpty, isNil } from 'lodash-es';
import Drawer from './Drawer.vue';
import Dropdown from './Dropdown.vue';
import { useNav, useNavList, type Nav, NavEnum } from '../hooks';
import DfMenu from './menu/DfMenu.vue';
import DfMenuItem from './menu/DfMenuItem.vue';

const { myNavigateTo } = useNav();

const hasSub = (nav: Nav) => {
  return !isEmpty(nav.subs ?? []) || !isNil(nav.imgInfo);
};

const active = ref('');
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    if (route.path === '/all' && route.query.types && route.query.types.includes('project')) {
      active.value = NavEnum.TEMPLATES;
    } else if (route.path.startsWith('/aimake')) {
      active.value = NavEnum.AIMAKE;
    } else {
      active.value = '';
    }
  },
  { immediate: true },
);

const { navList } = useNavList();
</script>

<style scoped lang="less">
.df-nav {
  //width: calc(100% - 221px);
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  :deep(.df-menu) {
    overflow-x: auto; /* 保持可滚动 */
    scrollbar-width: none; /* 对于 Firefox 6 */

    &::-webkit-scrollbar {
      display: none; /* 对于 Chrome, Safari 和 Opera */
    }
  }
}

:deep(.df-menu-item) {
  position: relative;
  .title {
    position: relative;
    white-space: nowrap;
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 20%;
      min-width: 16px;
      height: 4px;
      border-radius: 12px;
      background: #ff0035;
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;
    }
  }
  &.active,
  &.active:hover {
    color: #1a1a1a;
    background: transparent;
    .title::after {
      opacity: 1;
    }
  }
}
</style>

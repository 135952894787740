<template>
  <message-provider
    v-if="isLogin"
    :message-client="$messageClient"
    :list-style="{
      top: '60px',
    }"
  >
    <div
      class="message relative inline-flex h-10 w-10 items-center justify-center rounded-lg transition-all hover:bg-[#494F870D]"
      @click="onMessageClick"
    >
      <a-badge :count="unreadCount" :overflow-count="99">
        <icon-font type="icon-message-line" class="cursor-pointer text-[24px]" />
      </a-badge>
      <message-icon class="!absolute inset-0 z-[1] opacity-0" />
    </div>
  </message-provider>
</template>
<script setup lang="ts">
import { MessageProvider, MessageIcon } from '@makeblock/message-client-vue';

const { isLogin } = useLoginStatus();

const unreadCount = useState('message-unread-count', () => 0);

const { traceNavClick } = useSensors();
const onMessageClick = () => {
  traceNavClick({
    element_name: 'notification',
  });
};
</script>

<style scoped lang="less">
.message {
  :deep(.ant-badge) {
    .ant-badge-count {
      height: 16px;
      min-width: 16px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 16px;
      box-shadow: 0 0 0 2px #fff;

      &.ant-badge-multiple-words {
        padding: 0 4px;
      }
    }

    .ant-scroll-number-only {
      height: 16px;
      font-size: 12px;
      line-height: 16px;
      .ant-scroll-number-only-unit {
        height: 16px;
      }
    }
  }
}
</style>

<style>
.x-message-icon {
  position: absolute !important;
  opacity: 0 !important;
}
</style>

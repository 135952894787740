<template>
  <div id="header-bar" class="fixed top-0 z-[1000] w-full">
    <template v-if="isPcWeb">
      <PcHeader />
      <MbHeader />
    </template>
    <div
      v-if="isOpenInPcXcsCanvas"
      class="flex h-[66px] items-center justify-center"
      style="background: rgba(255, 255, 255, 0.92); backdrop-filter: blur(16px)"
    >
      <h1 class="hidden"><a href="//atomm.com/" @click.prevent="">Atomm</a></h1>
      <div class="inline-flex items-center">
        <Logo class="h-10" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PcHeader from './pc-header/index.vue';
import MbHeader from './mb-header/index.vue';
import { useNavList } from './hooks';

const route = useRoute();

// 在正常内容站中(非画布、非studio editor)
const isPcWeb = computed(() => {
  return !isOpenInPcXcsCanvas.value && !isOpenFromStudioEditor();
});

// XCS 画布左侧工具栏打开 ALL 页面
const isOpenInPcXcsCanvas = computed(() => {
  const query = route.query;
  return query.openFrom === 'xcs' && isInPCApp() && (route.path === '/all' || route.path.startsWith('/project'));
});

const { navList, navListLoaded, loadNavList } = useNavList();
onMounted(loadNavList);
if (!navListLoaded.value) {
  await loadNavList();
}
if (import.meta.client) {
  console.log('[navs]', navList.value);
}
</script>

<style lang="less"></style>

<template>
  <div class="df-user-wrap h-10">
    <df-btn v-if="!isLogin" type="primary" @click="login">Login</df-btn>
    <User v-else :footer="footer" @item-click="onItemClick" @avatar-click="onAvatarClick" @credits-click="onCreditsClick" />
  </div>
</template>

<script setup lang="tsx">
import { useRoute } from 'vue-router';
// import { userGuide } from '~/stores/userGuide';

import { User } from '@atomm/atomm-pro';
import { useSensors } from '~/composables';
import { useUserInfo } from '~/stores/user';

const route = useRoute();

const { $client } = useNuxtApp();

const { isLogin, broadcastLogout } = useLoginStatus();
const userInfoStore = useUserInfo();

const login = () => {
  if (route.path === '/studio') {
    $client.openModal(() => {
      // 存在cbUrl，则跳转，否则跳转studio-home页
      const cbUrl = route.query.cbUrl;
      location.replace(cbUrl ? decodeURIComponent(cbUrl as string) : import.meta.env.VITE_STUDIO_HOME_SITE);
    });
    return;
  }

  $client.login();
};
const logout = async () => {
  try {
    broadcastLogout();
    await $client.logout();
  } finally {
    userInfoStore.resetUserInfo();

    const { sensors }: any = useSensors();
    sensors.logout();
    // 如果在个人中心退出登录自动跳转首页
    if (route.path === '/user-center') {
      window.location.href = '/';
    }
    window.location.reload();
  }
};

const onItemClick = (item: any) => {
  console.log(item);
  switch (item.id) {
    case 'creator-center':
      navigateTo('/creator/center/dashboard');
      break;
    case 'purchase-history':
      navigateTo('/user-center?active=orders&source=usercard');
      break;
    case 'bookmarks':
      navigateTo('/user-center?active=favorites&source=usercard');
      break;
    case 'points':
      navigateTo('/rewards');
      break;
    case 'preferences':
      navigateTo('/preferences');
      break;
    default:
      break;
  }
};

const footer: Array<any> = [
  {
    label: 'Logout',
    icon: () => <icon-font type="icon-logout-box-line" />,
    onClick: logout,
  },
];

const onAvatarClick = () => {
  navigateTo('/user-center?source=usercard');
};
const onCreditsClick = () => {
  navigateTo('/user-center?active=credits&source=usercard');
};

/* const userGuideInfo = userGuide();
// 捕获点击事件，隐藏popover
const isAvatarPopoverVisible = ref(false);
function onAvatarPopoverClick() {
  isAvatarPopoverVisible.value = false;
}
const handleVisibleChange = (visible: boolean) => {
  if (visible) {
    userGuideInfo.hide(); // 隐藏新手引导
  }
};*/
</script>

<style scoped lang="less">
.user-menu {
  display: flex;
  flex-direction: column;
  width: 276px;
  padding: 2px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.06),
    0 1px 20px 0 rgba(0, 0, 0, 0.1);
  //overflow: hidden;

  a {
    display: inline-flex;
    align-items: center;
    padding: 7px 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #212121;

    &:hover {
      color: #1a1a1a;
      background: #f5f7fb;
    }

    &:first-child:hover {
      border-radius: 10px 10px 0 0;
    }

    &:last-child:hover {
      border-radius: 0 0 10px 10px;
    }

    .anticon {
      margin-right: 12px;
      font-size: 20px;
    }
  }
}
</style>

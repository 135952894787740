import type { FilterItemType, FilterVal } from '@/components/all/interface';
import { CONTENT_TYPE } from '~/types';

export const enum FilterTy {
  PROJECT = CONTENT_TYPE.PROJECT,
  PROJECT_WITH_INSTRUCTION = 'projectWithInstruction',
  DESIGNS = CONTENT_TYPE.DESIGNS,
  DISCUSSION = CONTENT_TYPE.DISCUSSION,
  CREATORS = CONTENT_TYPE.CREATORS,
}

export const SearchResultTabs = [
  {
    key: FilterTy.PROJECT_WITH_INSTRUCTION,
    name: 'Project with instruction',
  },
  {
    key: FilterTy.PROJECT,
    name: 'Project',
  },
  {
    key: FilterTy.DESIGNS,
    name: 'Designs',
  },
  {
    key: FilterTy.DISCUSSION,
    name: 'Discussions',
  },
  {
    key: FilterTy.CREATORS,
    name: 'Creators',
  },
];

export const Selection = [
  { key: 'content', label: 'All' },
  { key: 'tag', label: 'Tag' },
  { key: 'title', label: 'Title' },
  { key: 'creator', label: 'Creator' },
];

export const ProjectsOptions = [
  { label: 'Premium Projects', value: 'prime' },
  { label: 'Community Created Projects', value: 'creations' },
];

export enum TabMap {
  ASSET = 'asset',
  USER = 'user',
  MATERIALS = 'materials',
}
// asset  左侧筛选项, 当后端没返回筛选项时，则使用 items 作为筛选项
export const FilterItems: FilterItemType[] = [
  // with
  {
    key: 'with',
    items: [
      { name: 'With instruction', id: 'instruction' },
      { name: 'With settings', id: 'setting' },
    ],
  },
  // Included Files
  {
    title: 'Included Files',
    key: 'includeFiles',
    items: [
      { name: 'XCS', id: 'xcs' },
      { name: 'SVG', id: 'svg' },
      { name: 'PNG', id: 'png' },
      { name: 'JPG', id: 'jpg' },
      { name: 'DXF', id: 'dxf' },
    ],
    columns: 2,
  },
  // Project Type
  {
    title: 'Project Type',
    key: 'payTypes',
  },
  { title: 'Materials', key: 'materials', columns: 2 },
  { title: 'Skill Level', key: 'skillLevels' },
];

// 初始化选中项
export const SelectInitData: FilterVal = {
  with: [],
  includeFiles: [],
  payTypes: [],
  materials: [],
  skillLevels: [],
  types: ['project'],
  // ====
  hasInstruction: [],
  downloadType: [],
  machines: [],
};

import { onScopeDispose } from 'vue';
import type { Fn } from '~/types/fns';
export function useTimeout() {
  let timer: any;
  const exec = (fn: Fn, ms = 0) => {
    cancel();
    timer = setTimeout(() => fn(), ms);
  };
  const cancel = () => {
    clearTimeout(timer);
  };
  onScopeDispose(cancel);
  return {
    exec,
    cancel,
  };
}

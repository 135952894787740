<template>
  <a-drawer
    v-model:open="visible"
    :width="isMobile ? '100%' : '640px'"
    :mask-closable="true"
    :closable="false"
    :title="t('turorial.modalTitle')"
    placement="right"
    @close="onClose"
  >
    <template #extra>
      <icon-font class="text-[20px]" type="icon-a-Property1X" @click="onClose" />
    </template>
    <div class="content">
      <div class="relative pb-[34px]">
        <p class="mb-[16px] font-medium text-[#1A1A1E]">
          {{ t('turorial.mobileTitle') }}
        </p>
        <video
          src="https://image-res.xtool.com/resource/static/xtool/mt/video/mobile.mp4"
          controls
          class="mb-[24px] h-[333px] w-[580px] rounded-[2px] bg-[#545458]"
          :class="isMobile ? 'h-[188px] w-full' : ''"
        ></video>
        <div class="qacode cursor-pointer">
          <span class="">{{ t('turorial.mobileTip') }}</span>
          <img :src="qacodePng" alt="" class="code-img hidden w-full" />
          <span class="hidden">{{ t('turorial.install') }}</span>
        </div>
      </div>
      <a-divider />
      <div>
        <p class="mb-[16px] text-[16px] font-medium text-[#1A1A1E]">
          {{ t('turorial.pcTitle') }}
        </p>
        <div class="mb-5 w-full">
          <div class="mb-[24px]">
            <p class="mb-[8px]">
              <span class="text-[#28BE44]">{{ t('turorial.step1') }}</span>
              {{ t('turorial.step1Desc') }}
            </p>
            <a-image width="100%" :preview="true" :src="step1Png" />
          </div>
          <div class="mb-[24px]">
            <p class="mb-[8px]">
              <span class="text-[#28BE44]">{{ t('turorial.step2') }}</span>
              {{ t('turorial.step2Desc') }}
            </p>
            <a-image width="100%" :preview="true" :src="step2Png" />
          </div>
          <div class="mb-[24px]">
            <p class="mb-[8px]">
              <span class="text-[#28BE44]">{{ t('turorial.step3') }}</span>
              {{ t('turorial.step3Desc') }}
            </p>
            <a-image width="100%" :preview="true" :src="step3Png" />
          </div>
          <div class="mb-[24px]">
            <p class="mb-[8px]">
              <span class="text-[#28BE44]">{{ t('turorial.step4') }}</span>
              {{ t('turorial.step4Desc') }}
            </p>
            <a-image width="100%" :preview="true" :src="step4Png" />
          </div>
          <div class="mb-[24px]">
            <p class="mb-[8px]">
              {{ t('turorial.step5Desc') }}
            </p>
            <a-image width="100%" :preview="true" :src="step5Png" />
          </div>
        </div>
        <div class="btn relative cursor-pointer text-center leading-[40px]" @click="toXcs">
          <span>{{ t('turorial.pcTip') }}</span>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script lang="ts" setup>
import qacodePng from '@/assets/img/easyset/turorial/qacode.png';
import step1Png from '@/assets/img/easyset/turorial/step1.png';
import step2Png from '@/assets/img/easyset/turorial/step2.png';
import step3Png from '@/assets/img/easyset/turorial/step3.png';
import step4Png from '@/assets/img/easyset/turorial/step4.png';
import step5Png from '@/assets/img/easyset/turorial/step5.png';

import { useCommonStore } from '@/stores/common';
import { ref } from 'vue';

const { t } = useI18n();
const commonStore = useCommonStore();
const isMobile = ref(false);
watch(
  () => commonStore.isTutorialShow,
  (val: boolean) => {
    const userAgent = navigator.userAgent;
    isMobile.value = /Mobile/i.test(userAgent);
    visible.value = val;
  },
);

const visible = ref(false);

const onClose = () => {
  commonStore.updateTutorialShow(false);
};

const toXcs = () => {
  window.open(`https://www.xtool.com/pages/software`, '_blank');
};
</script>

<style lang="less" scoped>
.qacode {
  position: absolute;
  left: 0;
  display: flex;
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid #d9d9de;
  background-color: #fff;
  z-index: 99;
  transform: translateY(-6px);
  &:hover {
    padding: 8px 20px;
    height: auto;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
    .tip {
      color: #97999c;
    }
    img {
      display: block;
    }
    span {
      display: block;
    }
  }
}

.code-img {
  width: 245px;
  height: 245px;
  box-shadow: 0px 8px 16px 0px rgba(64, 68, 72, 0.12);
}
.btn {
  display: inline-block;
  height: 40px;
  padding: 0 20px;
  border-radius: 2px;
  border: 1px solid #d9d9de;
  background-color: #fff;
  &:hover {
    border: 1px solid #28be44;
    color: #28be44;
  }

  &:active {
    border: 1px solid #28be44;
    color: #28be44;
  }
}
</style>

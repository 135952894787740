export type CatalogTy = 'projects' | 'designs' | 'premium' | '';

export enum NavEnum {
  TEMPLATES = 'templates',
  AIMAKE = 'AImake',
  LEARN = 'learn',
}

export interface Nav {
  key: NavEnum;
  type: 'drawer' | 'dropdown';
  name: string;
  label: string;
  path?: string;
  imgInfo?: NavImgInfo;
  subs?: NavSub[];
  style?: 'icon' | 'none';
  offset?: number;
}

export interface NavSub {
  icon?: string;
  label: string;
  path: string;
  target?: '_blank' | '';
  items: NavSubItem[];
}

export interface NavSubItem {
  label: string;
  path: string;
}

export interface NavConfig {
  catalogType: CatalogTy;
  imgInfo?: NavImgInfo;
  catalogs?: Catalog[];
}

export interface NavImgInfo {
  title: string;
  image: string;
  desc: string;
  link: string;
}

export interface Catalog {
  id: number;
  catalogName: string;
  items: CatalogItem[];
}

export interface CatalogItem {
  name: string;
  path: string;
}

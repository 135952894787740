<template>
  <div ref="dropdownRef" class="overflow-hidden rounded-xl">
    <div v-if="!keyword" class="px-4 py-2">
      <div v-if="historyWord && historyWord.length > 0">
        <div class="mb-2 flex h-[24px] items-center justify-between">
          <span class="text-level-7 text-text-tertiary">Search History</span>
          <span class="cursor-pointer select-none text-level-7 text-text-tertiary" @mousedown="clearAllHistory">Clear all</span>
        </div>
        <div class="mb-4 flex flex-wrap gap-2">
          <span
            v-for="item in historyWord"
            :key="item"
            :class="[
              'max-w-full px-3 py-[6px]',
              'bg-[#fff]',
              'text-level-5 text-text-primary',
              'rounded-md border border-solid border-text-primary',
              'border-opacity-20 hover:border-opacity-100',
              'cursor-pointer transition-all',
              'flex items-center justify-center',
            ]"
            @click="chooseSuggest(item.searchContent, 'history')"
          >
            <span class="!inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap">{{ item.searchContent }}</span>
            <icon-font type="icon-close-line" class="text-[16px] text-text-tertiary" @mousedown="clearHistory(item.id)" />
          </span>
        </div>
      </div>

      <p class="mb-2 mt-2 h-[24px] text-level-7 leading-[24px] text-text-tertiary">Hot Search</p>
      <!-- 广告位 -->
      <div
        v-if="isShowAd"
        :class="[
          'px-[6px] py-[6px]',
          'bg-status-alert bg-opacity-10',
          'text-level-5 text-status-alert',
          'rounded-md',
          'border-opacity-20 hover:border-opacity-100',
          'whitespace-pre-wrap break-all',
          'cursor-pointer transition-all',
          'flex items-center',
        ]"
        @mousedown="handleAdClick"
      >
        <icon-font type="icon-local_fire_department1" class="text-[20px] text-status-alert" />
        {{ adverts?.word }}
      </div>
      <div class="flex flex-wrap justify-between">
        <span
          v-for="item in hotWords"
          :key="item"
          :class="[
            'w-[calc(50%-8px)]',
            'mt-2 px-[6px] py-[6px]',
            'bg-[#fff] active:bg-text-primary',
            'text-level-5 text-text-primary',
            'rounded-md',
            'border-opacity-20 hover:bg-background-2',
            'whitespace-pre-wrap break-all',
            'cursor-pointer transition-all',
            'flex items-center gap-1',
          ]"
          @mousedown="chooseSuggest(item, 'hotSearch')"
        >
          <img src="../../assets/img/fire.svg" alt="" />
          <span>{{ item }}</span>
        </span>
      </div>
      <div class="my-2 mt-3 flex items-center justify-between">
        <span class="text-level-7 text-text-tertiary">You may like</span>
      </div>
      <div class="mb-2 flex justify-between">
        <router-link
          v-for="item in likeList"
          :key="item.id"
          class="img-like h-[79px] w-[calc(25%-6px)]"
          :to="`/project/${item.id}`"
          @click="() => emit('emitToggleDropdown', false)"
        >
          <img :src="item.cover || DEFAULT_IMG" alt="ad" class="df-skeleton h-full w-full cursor-pointer rounded-sm object-cover" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { getPreferenceMakings } from '@/api/preference';
import DEFAULT_IMG from 'assets/img/creator/insight-no-img.png';
import { isEmpty } from 'lodash-es';
import { clearSearchHistoryApi, getSearchHistoryApi, getSearchHotWordsApi } from '~/api/search';
import { useAdvertising } from '~/components/ad/ad.hook';
import type { AdvertsResponse } from '~/components/ad/interface';

const { sensorsRecommendClick } = useSensors();
type Props = {
  keyword: string;
  isFocus?: boolean;
  highLightIdx: number;
};
type EmitsType = {
  (e: 'emitSuggestData', data: any): void;
  (e: 'emitChangeKeyword', query: any): void;
  (e: 'emitReportToggleData', bool: boolean): void;
  (e: 'emitToggleDropdown', state: boolean): void;
};
const emit = defineEmits<EmitsType>();
const props = defineProps<Props>();

// 搜索热词
const suggestWord = ref('');
const hotWords = ref([]);
const getHotWords = async () => {
  const { data } = await getSearchHotWordsApi();
  hotWords.value = data.value.data.slice(0, 10);
  const res = await getAdvertsApi({ type: ['search'] });
  adverts.value = res?.[0];
  isShowAd.value = !isEmpty(adverts.value);
  if (!props.keyword && props.isFocus) {
    emit('emitReportToggleData', hotWords.value.length !== 0);
  }
};
const chooseSuggest = (item: any, type: string) => {
  suggestWord.value = item;
  sensorsRecommendClick({
    recommend_type: type,
    recommend_word: item,
  });
  emit('emitChangeKeyword', item);
  emit('emitToggleDropdown', false);
};

// 广告位处理
const isShowAd = ref(false);
const adverts = ref<AdvertsResponse>();
const { getAdvertsApi } = useAdvertising();
const handleAdClick = () => {
  sensorsRecommendClick({
    recommend_type: 'advertisement',
    recommend_word: adverts.value?.word ?? '',
  });
  navigateTo(adverts.value?.link ?? '', {
    open: { target: '_blank' },
  });
};

// 搜索历史
const historyWord = ref([] as any[]);
const getHistory = async () => {
  const res = await getSearchHistoryApi({ current: 1, pageSize: 10 });
  historyWord.value = res.data.value.data.list;
};
const clearHistory = (id: number) => {
  console.log('clear history');
  try {
    clearSearchHistoryApi([id]);
    historyWord.value = historyWord.value.filter((item) => item.id !== id);
  } catch (error) {
    console.error(error);
  }
};
const clearAllHistory = () => {
  try {
    clearSearchHistoryApi();
    historyWord.value = [];
  } catch (error) {
    console.error(error);
  }
};

// 猜你喜欢
const likeList = ref([{}, {}, {}, {}] as any[]);
const getLike = async () => {
  const { cid } = useDeviceFlags();
  const res = await getPreferenceMakings.fetch({
    current: 1,
    pageSize: 20,
    cid: cid.value,
  });
  likeList.value = res.data.list.slice(0, 4);
  console.log('res: ', res);
};

const dropdownRef = ref<HTMLElement | null>(null);
// 点击外部区域处理
const handleClickOutside = (event: any) => {
  const target = event.target;
  if (dropdownRef.value && !dropdownRef.value.contains(target) && target.id !== 'searchInput') {
    emit('emitToggleDropdown', false);
  }
};

// 生命周期钩子
onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

const isLogin = computed(() => {
  return !!useMiddleCookie('utoken').value;
});
onMounted(() => {
  nextTick(() => {
    if (isLogin.value) {
      console.log();
      getHistory();
    }
    getHotWords();
    getLike();
  });
});
watch(
  () => props.isFocus,
  (newVal) => {
    if (newVal) {
      likeList.value = [{}, {}, {}, {}];
      getLike();
    }
  },
);
</script>
<style lang="less" scoped>
.mind {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #f5f7fb;
    }
    &.keyActivity {
      background: #f5f7fb;
    }
  }
  p {
    margin: 0;
    display: flex;
    align-items: center;

    font-size: 16px;
    color: #999;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
.img-like {
  position: relative;
  &:hover {
    &:after {
      content: '';
      position: absolute;
      border-radius: 2px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
.pc-show {
  display: block;
}
.xs-show {
  display: none;
}
@media screen and (max-width: 767px) {
  .pc-show {
    display: none;
  }
  .xs-show {
    display: block;
  }
}
</style>

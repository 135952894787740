<template>
  <DfCollapseTransition>
    <div v-show="open" class="pt-1">
      <div class="rounded-lg bg-[#F6F6FA] p-2">
        <NuxtLink to="/user-center?active=credits&source=usercard" class="credits cursor-pointer py-2" @click="onClickCredits">
          <div class="flex h-16 items-center justify-between rounded-[8px] bg-[#FF7C23] bg-opacity-10 px-3">
            <div class="flex items-center">
              <img :src="CREDIT_ICON" alt="" class="h-6 w-6 object-cover" />
              <div class="ml-1">
                <span class="text-level-3 font-bold text-[#FF7C23]">{{ creditCnt }}</span>
                <span class="ml-1 text-level-8 text-text-primary">Credits</span>
              </div>
            </div>
            <df-btn type="special" size="mini" class="!h-6 !text-[12px]" @click.prevent.stop="onPurchaseCredit">Buy</df-btn>
          </div>
        </NuxtLink>
        <div class="menu mt-2">
          <NuxtLink to="/creator/center/dashboard" @click="traceNavClick({ element_name: 'user_creator-center' })">
            <icon-font type="icon-a-CreatorCenter-line" />
            Creator Center
          </NuxtLink>
          <NuxtLink to="/user-center?active=orders&source=usercard" @click="traceNavClick({ element_name: 'user_purchase-history' })">
            <icon-font type="icon-a-CreatorCenter-line" />
            Purchase History
          </NuxtLink>
          <NuxtLink to="/user-center?active=favorites&source=usercard" @click="traceNavClick({ element_name: 'user_bookmarks' })">
            <icon-font type="icon-bookmark-line2" />
            Bookmarks
          </NuxtLink>
          <NuxtLink to="/rewards" @click="traceNavClick({ element_name: 'user_points' })">
            <icon-font type="icon-coin-line" />
            {{ pointCnt }}
          </NuxtLink>
          <NuxtLink to="/preferences" @click="traceNavClick({ element_name: 'user_preferences' })">
            <icon-font type="icon-settings-line" />
            Preferences
          </NuxtLink>
        </div>
      </div>
    </div>
  </DfCollapseTransition>
</template>
<script setup lang="ts">
import CREDIT_ICON from 'assets/img/credit/credit-icon.png';
import { useSensors } from '~/composables';
import { useUserInfo } from '~/stores/user';
import { useCreditService } from '@atomm/atomm-pro';

defineModel('open', {
  type: Boolean,
  default: false,
});

const { sensorsUserCreditClick, traceNavClick } = useSensors();

const userInfoStore = useUserInfo();
const pointCnt = computed(() => userInfoStore.shopifyUserInfo.points || 0);

const creditService = useCreditService();
const creditCnt = computed(() => creditService.myCreditCnt);
function onClickCredits() {
  traceNavClick({
    element_name: 'user_credits',
  });

  sensorsUserCreditClick({
    element_name: 'usercard',
  });
}
const onPurchaseCredit = () => {
  sensorsUserCreditClick({
    element_name: 'Buy',
  });

  creditService.openPurchaseModal({
    trace: {
      source: 'usercard',
    },
  });
};
</script>

import type { InjectionKey, VNode, Ref } from 'vue';

export const DF_MENU_CTX_KEY: InjectionKey<DfMenuCtx> = Symbol('DF_MENU_CTX_KEY');

export interface DfMenuCtx {
  activeKey: Ref<string>;
  openKey: Ref<string>;
  items: Record<string, DfMenuItemInstance>;
  timeout: ReturnType<typeof useTimeout>;
}

export interface DfMenuItemInstance {
  readonly type: 'drawer' | 'dropdown';
  readonly el?: HTMLElement;
  readonly offset?: number;
  content?: VNode;
}

<template>
  <section class="navs" @click.stop="">
    <a-collapse v-model:activeKey="activeKey">
      <a-collapse-panel v-for="item in items" :key="item.key" :show-arrow="false">
        <template #header>
          <div class="inline-flex w-full items-center justify-between gap-2.5 p-3">
            <NuxtLink :to="item.path" :target="item.target" class="flex-1 text-level-4 font-[500] !text-text-secondary" @click="onNavClick(item)">
              {{ item.label }}
            </NuxtLink>
            <icon-font v-if="item.subs.length > 0" type="icon-arrow-down-m" class="icon text-[16px] text-[#78808b] transition-all" />
          </div>
        </template>
        <div v-if="item.subs.length > 0" class="flex flex-col rounded-lg bg-[#F6F6FA] p-2">
          <NuxtLink
            v-for="subItem in item.subs"
            :key="subItem.path"
            :to="subItem.path"
            :target="subItem.target"
            class="inline-flex w-full px-2 py-2.5 text-level-4 !text-text-primary"
            @click="onSubNavClick(item, subItem)"
          >
            {{ subItem.label }}
          </NuxtLink>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </section>
</template>

<script setup lang="ts">
import { useNavList, NavEnum } from '../hooks';
import { useSensors } from '~/composables';

const activeKey = ref();

const { isLogin } = useLoginStatus();

const { navs } = useNavList();
const items = computed(
  () =>
    [
      {
        key: NavEnum.TEMPLATES,
        label: navs.value[NavEnum.TEMPLATES].label,
        path: navs.value[NavEnum.TEMPLATES].path,
        target: '_self',
        subs: [],
      },
      {
        key: NavEnum.AIMAKE,
        label: navs.value[NavEnum.AIMAKE].label,
        path: navs.value[NavEnum.AIMAKE].path,
        subs: navs.value[NavEnum.AIMAKE].subs,
        target: '_self',
      },
      {
        key: NavEnum.LEARN,
        label: navs.value[NavEnum.LEARN].label,
        subs: navs.value[NavEnum.LEARN].subs,
      },
      {
        key: 'studio',
        label: 'Studio',
        path: isLogin.value ? import.meta.env.VITE_ATOMM_STUDIO_SITE : `${import.meta.env.VITE_PUBLIC_SITE_URL}/studio`,
        target: '_blank',
        subs: [],
      },
    ] as any[],
);

const { traceNavClick } = useSensors();

const onNavClick = (nav: any) => {
  traceNavClick({
    element_name: nav.key,
  });
};
const onSubNavClick = (nav: any, subNav: any) => {
  traceNavClick({
    element_name: `${nav.key}_${subNav.label}`,
  });
};
</script>

<style scoped lang="less">
.navs {
  :deep(.ant-collapse) {
    border: none;
    background-color: #fff;

    & > .ant-collapse-item {
      border-bottom: none;

      &.ant-collapse-no-arrow > .ant-collapse-header {
        padding-inline-start: 0;
        .ant-collapse-header-text {
          display: block;
        }
      }

      & > .ant-collapse-header {
        padding: 0;
      }

      &.ant-collapse-item-active > .ant-collapse-header .ant-collapse-header-text .icon {
        transform: rotate(180deg);
      }
    }
    .ant-collapse-content {
      border-top: none;
      background-color: #fff;

      & > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}
</style>

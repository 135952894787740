<template>
  <a-drawer
    width="100%"
    height="80vh"
    placement="bottom"
    title="Select Template"
    destroy-on-close
    :open="layoutStore.isActMbModalShow"
    :closable="false"
    :z-index="zIndex"
    @close="onClose"
  >
    <div>
      <div class="mb-4 mt-0 flex justify-center rounded-xl border">
        <df-btn type="highlight" class="flex items-center" @click="goCreate">
          <icon-font type="icon-Add" class="text-[24px]" />
          <span class="text-[16px]">Create New Template</span>
        </df-btn>
      </div>

      <a-spin class="" :spinning="isSpinning">
        <div class="items mt-5 flex flex-wrap mobile:space-y-4" :style="{ 'gap': 'var(--gap)' }">
          <CommonActItem
            v-for="item in items"
            :key="item.id"
            ref="itemRefs"
            :item="item"
            :style="{
              width: `calc(100% / var(--w) - var(--gap)*(var(--w) - 1)/var(--w))`,
            }"
            @change="onChange(item, $event)"
          />
        </div>
      </a-spin>
    </div>

    <template #footer>
      <div class="footer sticky bottom-[0px] flex items-center justify-center gap-4 rounded-b-2xl bg-uni-6">
        <df-btn type="tertiary" @click="handleClose">Cancel</df-btn>
        <df-btn type="primary" :disabled="selectedItems.length < 1" :loading="loading" @click="onSelect">Select</df-btn>
      </div>
    </template>

    <CommonActSuccessModal ref="modalRef" @confirm="onConfirm" />
  </a-drawer>
</template>

<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout';
import { useCreateEntry } from '@/utils/createHook/hooks';
import { useAct } from './act/hook';

type PropsType = {
  visible: boolean;
  zIndex?: number;
};
withDefaults(defineProps<PropsType>(), {
  zIndex: 1009,
});
type EmitsType = {
  (event: 'update:visible', value: boolean): void;
};
const emits = defineEmits<EmitsType>();

const { selectedItems, loading, onSelect, items, isSpinning, onChange, handleConfirm, modalRef } = useAct();
const layoutStore = useLayoutStore();

const onClose = () => {
  layoutStore.setActMbModalShow(false);
  // layoutStore.setRemixBaseOnLink([]);
};

const handleClose = () => {
  emits('update:visible', false);
  layoutStore.setActModalShow(false);
};
const onConfirm = async () => {
  await handleConfirm();
  if (items.value.length <= 0) {
    handleClose();
  }
};
const { goEditorRoute } = useCreateEntry();
const goCreate = () => {
  handleClose();
  goEditorRoute();
};
</script>

<style lang="less" scoped>
.items {
  --gap: 0;
  --w: 1;
}
</style>

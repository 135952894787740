<template>
  <div class="dropdown">
    <div
      v-if="!isGridStyle"
      class="flex flex-col overflow-hidden rounded-md bg-[#FFF] p-2 shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_20px_0_rgba(0,0,0,0.1)]"
    >
      <a
        v-for="item in nav.subs"
        :key="item.label"
        :href="item.path"
        :target="item.target"
        class="inline-flex min-w-[195px] items-center justify-between gap-3 rounded p-2.5 text-text-primary hover:bg-[#072B570D]"
        @click.prevent="myNavigateTo(item.path, `${navKey}_${item.label}`)"
      >
        <span class="text-level-4">{{ item.label }}</span>
        <div v-if="item.target === '_blank'" class="h-4 w-4">
          <ClientOnly>
            <icon-font type="icon-external-link-line" class="text-[16px]" />
          </ClientOnly>
        </div>
      </a>
    </div>
    <div
      v-else
      class="flex gap-[42px] overflow-hidden rounded-xl bg-[#FFF] p-[14px] shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_20px_0_rgba(0,0,0,0.1)]"
    >
      <div v-for="(items, index) in groups" :key="index" class="flex flex-col">
        <a
          v-for="item in items"
          :key="item.label"
          :href="item.path"
          :target="item.target"
          class="group/item inline-flex min-w-[195px] items-center gap-3 rounded-[10px] p-2 hover:bg-[#FAFAFA]"
          @click.prevent="myNavigateTo(item.path, `${navKey}_${item.label}`)"
        >
          <img :src="item.icon || DEFU_ICON" :alt="item.label" class="h-10 w-10 overflow-hidden rounded object-cover" />
          <span class="text-level-4 text-text-primary">{{ item.label }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DEFU_ICON from '@/assets/img/common/nav-defu-icon.png';

import type { PropType } from 'vue';
import { type Nav, useNav } from '../hooks';
import { chunk } from 'lodash-es';

const props = defineProps({
  nav: {
    type: Object as PropType<Nav>,
    default: () => ({}),
  },
});
const { myNavigateTo } = useNav();

const navKey = computed(() => props.nav.key);
const nav = computed(() => props.nav);
const isGridStyle = computed(() => nav.value.style === 'icon');
const groups = computed(() => chunk(nav.value.subs, 4));
</script>

<style scoped lang="less">
.dropdown {
  &__list {
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.06),
      0 1px 20px 0 rgba(0, 0, 0, 0.1);

    a {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 12px;
      width: 100%;
      min-width: 195px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #212121;

      &:hover {
        color: #1a1a1a;
        background: #f5f7fb;
      }

      &:first-child:hover {
        border-radius: 10px 10px 0 0;
      }

      &:last-child:hover {
        border-radius: 0 0 10px 10px;
      }

      .anticon {
        font-size: 16px;
      }
    }
  }

  &__grid {
    padding: 14px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.06),
      0 1px 20px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    column-gap: 42px;
  }
}
</style>

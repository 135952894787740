import { isEmpty, isNil } from 'lodash-es';
import { getNavCatalogsApi, getNavImgApi } from '~/api';
import { type Nav, NavEnum } from './types';

export function useNav() {
  const router = useRouter();
  const route = useRoute();
  const { traceNavClick } = useSensors();
  const myNavigateTo = async (path: string, name?: string) => {
    if (!isEmpty(name) && !isNil(name)) {
      traceNavClick({
        element_name: name,
      });
    }
    if (isSameSiteURL(path)) {
      const url = getSameSiteURLPath(path);
      if (route.path === url && route.fullPath !== path) {
        router.$refresh(path);
      } else {
        await router.push(path);
      }
    } else {
      window.open(path, '_blank');
    }
  };
  return {
    myNavigateTo,
  };
}

const _navs: Record<NavEnum, Nav> = {
  [NavEnum.TEMPLATES]: {
    key: NavEnum.TEMPLATES,
    name: 'projects',
    type: 'drawer',
    label: 'Templates',
    path: '/all?types=project&sortType=recommend&page=1',
    offset: 14,
    subs: [],
  },
  [NavEnum.AIMAKE]: {
    key: NavEnum.AIMAKE,
    name: 'aimake',
    type: 'dropdown',
    label: 'AImake',
    path: '/aimake',
    offset: 14,
    subs: [],
  },
  [NavEnum.LEARN]: {
    key: NavEnum.LEARN,
    name: 'learn',
    type: 'dropdown',
    label: 'Learn',
    offset: 14,
    subs: [],
  },
};
export function useNavList() {
  const navListLoaded = useState(() => false);
  const navs = useState(() => _navs);
  const navList = computed(() => [navs.value[NavEnum.TEMPLATES], navs.value[NavEnum.AIMAKE], navs.value[NavEnum.LEARN]] as any[]);

  const parseSubItemPath = (item: any) => {
    let res = '';
    if (item.type === 'tag') {
      res = `/all?tagGroups=${item.tagGroupId}`;
    } else if (item.type === 'collection') {
      res = `/collection/${item.collectionId}`;
    }
    return res;
  };
  const parseSubPath = (item: any) => {
    const link = (item.link ?? '') as string;
    if (item.linkOpenType !== 'new' && isSameSiteURL(link)) {
      return getSameSiteURLPath(link);
    }
    return link;
  };
  const catalogToNavSub = (catalog: any) => {
    const items = catalog.items ?? [];
    const subItems = items.map((item: any) => ({
      label: item.type === 'tag' ? item.tagGroupName : item.collectionName,
      path: parseSubItemPath(item),
    }));
    return {
      icon: catalog.icon,
      label: catalog.catalogName,
      path: parseSubPath(catalog),
      target: catalog.linkOpenType === 'new' ? '_blank' : '_self',
      items: subItems,
    };
  };

  const loadNavList = async () => {
    try {
      await Promise.all([
        call(async () => {
          const res = await getNavImgApi.fetch();
          const list = res.data ?? [];
          list.forEach((item: any) => {
            const catalogType = item.catalogType === 'projects' ? NavEnum.TEMPLATES : item.catalogType;
            const nav = navs.value[catalogType as NavEnum];
            if (!isNil(nav)) {
              nav.imgInfo = item as any;
            }
          });
        }),
        call(async () => {
          const res = await getNavCatalogsApi.fetch();
          const map = res.data.map as Record<string, any>;
          Object.entries(navs.value).forEach(([, nav]) => {
            const menu = map[nav.name];
            if (isNil(menu)) {
              return;
            }
            nav.style = menu.iconStyle;
            nav.subs = menu.list.map((item: any) => catalogToNavSub(item));
          });
        }),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      navListLoaded.value = true;
    }
  };

  return { navList, navs, navListLoaded, loadNavList };
}

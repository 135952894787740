<template>
  <ClientOnly>
    <a-drawer
      width="100%"
      height="unset"
      placement="bottom"
      class="release-entry-modal"
      :closable="false"
      :z-index="1001"
      :open="modalVisible"
      @close="closeEntryModal"
    >
      <div class="px-4">
        <div class="flex justify-end py-3"><icon-font type="icon-close-line" class="text-[20px]" @click="closeEntryModal" /></div>

        <div :class="'mb-3 flex cursor-pointer items-center justify-start rounded-lg bg-[#fff] px-4 py-6 text-base'" @click="clickEntry">
          <icon-font class="relative text-[24px] font-medium" type="icon-Add" />
          <span class="font-semi ml-2">Create New Template</span>
          <icon-font class="ml-auto text-xl" type="icon-a-30-arrow-right-s-line" />
        </div>
        <div :class="'mb-3 flex cursor-pointer items-center justify-start rounded-lg bg-[#fff] px-4 py-6 text-base'" @click="goDraft">
          <icon-font class="relative text-[24px] font-medium" type="icon-Draft1" />
          <span class="font-semi ml-2">My Draft&nbsp;({{ draftCount }})</span>
          <icon-font class="ml-auto text-xl" type="icon-a-30-arrow-right-s-line" />
        </div>
      </div>
    </a-drawer>
    <DraftMbModal />
  </ClientOnly>
</template>
<script setup lang="ts">
import { getDraftCount } from '@/api/user';
import DraftMbModal from '@/components/share/create/DraftMbModal.vue';
import { useLayoutStore } from '@/stores/layout';
import { useCreateEntry } from '@/utils/createHook/hooks';

const { handleEntry } = useCreateEntry();

const emit = defineEmits(['update:visible']);

const props = defineProps<{
  visible: boolean;
}>();
const layoutStore = useLayoutStore();
const modalVisible = ref(false);
watch(
  () => props.visible,
  (val) => {
    if (val) {
      getNum();
      modalVisible.value = true;
    } else {
      modalVisible.value = false;
    }
  },
);
const draftCount = ref(0);
const getNum = async () => {
  const res = await getDraftCount();
  draftCount.value = res.data.value.data.total ? res.data.value.data.total : 0;
};
const closeEntryModal: any = () => {
  emit('update:visible', false);
};
const goDraft = () => {
  layoutStore.setIsShowMbDraft(true);
};
const clickEntry = () => {
  handleEntry();
  layoutStore.closeMbEditorEntryModal();
};
</script>
<style lang="less" scoped></style>
<style lang="less">
.ant-drawer-bottom > .ant-drawer-content-wrapper {
  box-shadow: none;
}
.release-entry-modal {
  overflow: hidden;

  &.ant-drawer-content {
    background: theme('colors.background.4');

    border-radius: 12px 12px 0 0;
    .ant-drawer-body {
      border-radius: 12px 12px 0 0;

      padding: 0;
    }
  }
}
</style>

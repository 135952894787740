<template>
  <a-popover v-model:open="visible" trigger="click" overlay-class-name="create-guide-popover" :get-popup-container="(node: any) => node?.parentNode">
    <template #content>
      <icon-font type="icon-close-line" class="absolute right-4 top-2 text-[24px] text-text-tertiary" @click="hide" />

      <div class="mb-1 flex items-center justify-center gap-2">
        <img :src="img3" alt="" class="h-[24px] w-[24px]" />
        <p class="mb-0 text-[20px] font-bold text-text-primary">Post your first template to earn</p>
      </div>
      <p class="mb-4 text-center text-[20px] font-bold text-text-primary">
        <span class="text-status-alert">800 credits</span>
        ! Use them to:
      </p>
      <div class="mb-2 flex items-center justify-center gap-3">
        <img :src="img1" alt="" class="w-[130px]" />
        <img :src="imgOr" alt="" class="w-[48px]" />
        <img :src="img2" alt="" class="w-[130px]" />
      </div>
      <div class="mb-3 flex items-center justify-center gap-[70px]">
        <p class="mb-0 w-[130px] text-center text-[14px] font-semibold text-text-primary">
          Freely purchase
          <br />
          premium templates
        </p>
        <p class="mb-0 w-[130px] text-center text-[14px] font-semibold text-text-primary">
          Generate images
          <br />
          with AImake
        </p>
      </div>
    </template>
  </a-popover>
</template>
<script lang="ts" setup>
import img1 from '@/assets/img/creator/new-create/1.webp';
import img2 from '@/assets/img/creator/new-create/2.webp';
import img3 from '@/assets/img/creator/new-create/3.png';
import imgOr from '@/assets/img/creator/new-create/or.png';
import { ObjectType, TrackType } from '@/types/track';
import { getMyObjectTrack, postMyObjectTrack } from '~/api/user';
import { useUserInfo } from '~/stores/user';
import { userGuide } from '~/stores/userGuide';
const userGuideInfo = userGuide();
const userInfoStore = useUserInfo();
const visible = computed(() => userGuideInfo.isVisible);
const myInfo = computed(() => userInfoStore.myInfo);
const hide = () => {
  userGuideInfo.hide();
};

const { isPc } = useScreen();
const { isLogin } = useLoginStatus();
const lastTrackTime = ref(null) as any;
const showTotal = ref(null) as any;

/*
 是否显示引导：
 1.移动端-不展示
 2.未登录-不展示
 3.发过首个案例 - 不展示
 4.注册时长24小时内- 不展示
 5.关闭三次后不再展示
 6.24内展示过则不展示
  **/
const checkShow = () => {
  // 未发过案例
  const isNew = !myInfo.value?.isFirstPublishMaking;
  // 注册时长 > 24小时
  const registerTime = myInfo.value.createTime * 1000;
  const now = new Date().getTime();
  const isOldRegister = now - registerTime > 24 * 60 * 60 * 1000;
  // 关闭次数 < 三次
  const hasTimes = showTotal.value < 3;
  // 24小时未内展示过
  const isShow = now - lastTrackTime.value > 24 * 60 * 60 * 1000 || !lastTrackTime.value;
  // const isShow = now - lastTrackTime.value > 10 * 60 * 1000 || !lastTrackTime.value; // 测试改成10分钟
  if (isNew && isOldRegister && hasTimes && isShow) {
    handleShow();
  }
};
// 上报
const handleShow = () => {
  userGuideInfo.show();
  postMyObjectTrack({
    objectType: ObjectType.USER,
    objectId: myInfo.value.id,
    trackType: TrackType.USER_HINT,
  });
};

watch(
  () => [myInfo.value],
  () => {
    if (myInfo.value.id > 0 && isPc.value && isLogin.value) {
      // 已登陆状态且pc,拿到用户id再请求引导信息
      getMyObjectTrackData();
    }
  },
  {
    deep: true,
  },
);
const getMyObjectTrackData = async () => {
  try {
    const res = await getMyObjectTrack({
      objectType: ObjectType.USER,
      objectId: myInfo.value.id,
      trackType: TrackType.USER_HINT,
    });
    const data = res.data.value.data;
    lastTrackTime.value = data.lastTrackTime * 1000;
    showTotal.value = data.total;
    checkShow();
  } catch (e) {
    console.log(['e', e]);
  }
};
</script>
<style lang="less">
.create-guide-popover {
  top: 43px !important;
  border-radius: 12px;
  left: auto !important;
  right: 16px;
  .ant-popover-inner {
    padding: 24px;
    width: 412px;
    // background: linear-gradient(120deg, rgba(255, 236, 238, 0.8) 6.94%, rgba(255, 229, 234, 0) 32.61%), #fff;
    box-shadow:
      0 -6px 6px -4px #0000001f,
      0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
    // padding-top: 10px;
  }
  .ant-popover-arrow {
    width: 24px;
  }

  &.ant-popover .ant-popover-arrow:before {
    width: 24px;
    height: 12px;
    clip-path: polygon(2.485281374238571px 100%, 50% 2.485281374238571px, 21.51471862576143px 100%, 2.485281374238571px 100%);
  }
  &.ant-popover-placement-bottom .ant-popover-arrow {
    left: calc(50% + 55px);
  }
  &.ant-popover .ant-popover-arrow::after {
    width: 14px;
    height: 12px;
  }
  .ant-popover-arrow-content {
    box-shadow: 3px 3px 7px #00000012;
  }
}
</style>

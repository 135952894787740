<template>
  <div id="footer-wrap" class="footer-wrap flex h-8 items-center justify-between mobile:hidden">
    <span class="text-level-8 text-text-tertiary">
      {{ copyRightText }}
      <a class="text-text-tertiary hover:text-status-link" href="mailto:designfind@xtool.com">designfind@xtool.com</a>
    </span>

    <span class="inline-flex space-x-6">
      <a href="https://www.atomm.com/discussion/3" target="_blank" class="text-level-8 text-text-tertiary hover:text-status-link">
        Community Regulation
      </a>
      <a href="/atomm-terms_v2.html" target="_blank" class="text-level-8 text-text-tertiary hover:text-status-link">Atomm Terms</a>
      <a href="https://passport.xtool.com/policy.html" target="_blank" class="text-level-8 text-text-tertiary hover:text-status-link">
        Privacy Policy
      </a>
      <a href="https://passport.xtool.com/terms.html" target="_blank" class="text-level-8 text-text-tertiary hover:text-status-link">
        Terms of Service
      </a>
    </span>
  </div>
</template>

<script setup lang="ts">
const copyRightText = computed(() => {
  const time = new Date().getFullYear();
  return `Copyright © ${time} Makeblock Europe B.V. All Rights Reserved`;
});
</script>

<style lang="less" scoped>
.footer-wrap {
  // position: fixed;
  // z-index: 50;
  // bottom: 0;
  width: 100%;
  padding: 0 40px;
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(16px);
  border-top: 1px solid #d3d3d3;
  @media (max-width: 992px) {
    display: none;
  }
}
</style>

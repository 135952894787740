import { defineStore } from 'pinia';
import { doActionApi } from '~/api';
import { deleteMakingApi, getMakingAuditDetailApi, getMakingDetailApi, getTemplateIdApi } from '~/api/project-details';
import { priceComputed } from '~/components/project-details/common/price';
import { CONTENT_TYPE, OBJECT_TYPE, type ItemType } from '~/types';

export const useDetailsStore = defineStore('detailsStore', {
  state: () => {
    return {
      detail: {} as ItemType,
      pageLoading: false,
      downloadCnt: 0,
      likeModalShow: false,
      isCurrentDownload: false,
      commentCount: 0,
    };
  },
  getters: {
    isForSale: (state) => state.detail?.forSale,
    priceInfo: (state) => priceComputed(state.detail),
  },
  actions: {
    resetStore() {
      this.detail = {} as ItemType;
      this.pageLoading = false;
      this.downloadCnt = 0;
      this.likeModalShow = false;
      this.isCurrentDownload = false;
      this.commentCount = 0;
    },
    async getProjectDetailById(id: number) {
      try {
        this.pageLoading = true;
        const { data: resp, error } = await getMakingDetailApi(id);
        if (error.value) {
          const { statusCode } = error.value;
          if (statusCode === 404) {
            useErrorPage({
              statusCode: 404,
            });
          } else {
            message.error('Unknown system error!');
          }
        } else {
          if (resp.value?.code === 0) {
            this.detail = resp.value?.data ?? {};
            this.initDownloadCnt();

            // this.detail.type = 'xthings';
            // this.detail.hasDiscount = true;
            // this.detail.discount = {
            //   disPrice: 99.99,
            //   discountPercent: 17,
            //   endTime: 1760267588,
            //   startTime: 1730257588,
            // };

            if (import.meta.client) {
              // document.title = this.detail.title;
              console.log('this.detail :>> ', this.detail);
            }
          }
        }
      } catch (err) {
        console.log('getProjectDetailById-err :>> ', err);
      } finally {
        this.pageLoading = false;
      }
    },
    async refresh() {
      await this.getProjectDetailById(this.detail.id);
    },
    setDetail(detail: ItemType | undefined) {
      this.detail = detail ?? ({} as ItemType);
      this.initDownloadCnt();
    },
    async getAuditDetailById(id: number) {
      try {
        this.pageLoading = true;
        const { data: resp, error } = await getMakingAuditDetailApi(id);
        if (error.value) {
          const { statusCode } = error.value;
          if (statusCode === 404) {
            useErrorPage({ statusCode: 404 });
          } else {
            message.error('Unknown system error!');
          }
        } else {
          if (resp.value?.code !== 0) {
            message.error(ResponseMessage[resp.value?.code] || 'Unknown system error!');
            useErrorPage({ statusCode: 404 });
          } else {
            this.detail = resp.value?.data ?? {};
          }
        }
      } catch (err) {
        console.log('getAuditDetailById-err :>> ', err);
      } finally {
        this.pageLoading = false;
      }
    },
    togglePageLoading(state: boolean) {
      this.pageLoading = state;
    },
    initDownloadCnt() {
      this.downloadCnt = this.detail?.actionSum?.download ?? 0;
      this.isCurrentDownload = this.detail?.userAction?.download === 0 ? false : true;
    },
    updateCommentCount(count?: number) {
      this.commentCount = count || 0;
    },
    addDownloadCnt() {
      this.downloadCnt++;
    },
    // 触发下载以增加下载量
    async triggerAction(type: string = 'download') {
      const token = useMiddleCookie('utoken');
      if (!token.value) {
        return;
      }
      if (this.detail.userAction?.download === 0 && !this.isCurrentDownload) {
        try {
          const { data } = await doActionApi(type, {
            objectType: OBJECT_TYPE.MAKING,
            objectId: this.detail.id,
          });

          if (data?.value?.code === 0) {
            this.isCurrentDownload = true;
            this.addDownloadCnt();
          }
        } catch (error) {
          console.log('triggerAction-err :>> ', error);
        }
      }
    },
    // 下载成功显示弹窗
    showLikeModal(fileName: string, opts: any) {
      this.likeModalShow = true;
      const { ga4DownpopSuccess, sensorsDownpopSuccess } = useSensors();
      const params = window?.df_shen?.getParams();
      const p = {
        content_id: this.detail?.id,
        file_name: fileName,
        is_free: !this.isForSale,
        entry: opts.position,
        latest_page_name: params?.latest_page_name,
        latest_block_name: params?.latest_block_name,
        latest_keyword: params?.latest_keyword,
        latest_scene_name: params?.latest_scene_name,
        latest_contentid_algorithm_version: params?.latest_contentid_algorithm_version,
        latest_contentid_algorithm_type: params?.latest_contentid_algorithm_type,
      };
      ga4DownpopSuccess(p);
      sensorsDownpopSuccess(p as any);
    },
    handleToolStatusClick(type: string) {
      const hasCookie = !!useMiddleCookie('utoken')?.value;
      switch (type) {
        case 'edit':
          this.editMaking(this.detail.type as CONTENT_TYPE, this.detail.id);
          break;
        case 'favorite':
        case 'like':
          handleToggleAction({
            objectType: OBJECT_TYPE.MAKING,
            item: this.detail,
            actionType: type,
            hasCookie: hasCookie,
          });
          break;
        default:
          break;
      }
    },
    // 编辑作品
    editMaking(type: CONTENT_TYPE, id: number, isAudit?: boolean) {
      const router = useRouter();
      const route = useRoute();
      const isXthings = type === CONTENT_TYPE.XTHINGS;
      if (isXthings) {
        router.push({ path: `/edit/${type}/${id}` });
      } else {
        router.push({
          path: `/share/${type}`,
          query: {
            ...route.query,
            id,
            infoType: isAudit ? 'reAudit' : undefined,
          },
        });
      }
    },
    // 删除作品
    deleteMakingById(id: number) {
      Modal.confirm({
        content: 'Are you sure you want to delete this making?',
        okText: 'Delete',
        onOk: async () => {
          try {
            const { data: resp } = await deleteMakingApi(id);
            const { code } = resp.value;
            if (code === 0) {
              message.success('Deleted successfully');
              const router = useRouter();
              router.replace({
                path: '/user-center',
                query: {
                  active: 'projects',
                },
              });
            }
          } catch {
            message.error('Sorry, something went wrong, please try again later!');
          }
        },
      });
    },
    // 复制模版
    useTemplateById(id: number, mode: string) {
      Modal.confirm({
        content: h('div', {
          innerHTML:
            'Click here to create a new page with the current content copied over. You can start editing immediately without starting from scratch.',
          style: {
            marginTop: '16px',
          },
        }),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: async () => {
          try {
            const { data: resp } = await getTemplateIdApi(id);
            const { code, data } = resp.value;
            if (code === 0 && data.id) {
              const { id, type } = data;
              const router = useRouter();
              setTimeout(() => {
                router.push({
                  path: `/share/${type}`,
                  query: {
                    id,
                    mode,
                    source: 'copyTemplate',
                  },
                });
              }, 300);
            }
          } catch {
            message.error('Sorry, something went wrong, please try again later!');
          }
        },
      });
    },
    // 滚动到 comment 栏目的 remix 列表区域
    scrollToRemix() {
      const remixDom = document.getElementById('anchor-page-remixes');
      const headBarDom = document.getElementById('header-bar');
      const stickyBarDom = document.getElementById('sticky-tab-bar');
      if (remixDom && headBarDom && stickyBarDom) {
        const rect = remixDom.getBoundingClientRect();
        const headBarRect = headBarDom.getBoundingClientRect();
        const stickyBarRect = stickyBarDom.getBoundingClientRect();
        nextTick(() => {
          window.scrollTo({
            top: window.scrollY + rect.top - headBarRect.height - stickyBarRect.height - 20,
            behavior: 'smooth',
          });
        });
      }
    },
    // 滚动到 Information 栏目的 remix base on 区域
    scrollToRemixBaseOn() {
      const remixDom = document.getElementById('remix-base-on');
      const headBarDom = document.getElementById('header-bar');
      const stickyBarDom = document.getElementById('sticky-tab-bar');
      if (remixDom && headBarDom && stickyBarDom) {
        const rect = remixDom.getBoundingClientRect();
        const headBarRect = headBarDom.getBoundingClientRect();
        const stickyBarRect = stickyBarDom.getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + rect.top - headBarRect.height - stickyBarRect.height - 20,
          behavior: 'smooth',
        });
      }
    },
    // 滚动到对应的 tab 栏
    scrollToTab() {
      const tabDom = document.getElementById('anchor-content-box');
      const headBarDom = document.getElementById('header-bar');
      const stickyBarDom = document.getElementById('sticky-tab-bar');
      if (tabDom && headBarDom && stickyBarDom) {
        const rect = tabDom.getBoundingClientRect();
        const headBarRect = headBarDom.getBoundingClientRect();
        const stickyBarRect = stickyBarDom.getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + rect.top - headBarRect.height - stickyBarRect.height,
          behavior: 'smooth',
        });
      }
    },
  },
});

<template>
  <div class="overflow-hidden rounded-md">
    <div v-if="keyword" class="mind">
      <div class="px-3 py-2" :class="highLightIdx == -1 ? 'keyActivity' : ''" @mousedown="chooseSuggest(keyword, 'associate')">
        <icon-font class="pc-show mr-2 text-[20px] text-text-disabled" type="icon-search-line" />
        <p>
          <span class="text-text-tertiary">{{ keyword }}</span>
        </p>
      </div>
      <div
        v-for="(item, idx) in suggestData"
        :key="item"
        class="px-3 py-2"
        :class="highLightIdx == idx ? 'keyActivity' : ''"
        @mousedown="chooseSuggest(item, 'associate')"
      >
        <icon-font class="pc-show mr-2 text-[20px] text-text-disabled" type="icon-search-line" />
        <p class="whitespace-wrap max-w-full text-text-secondary" v-html="processHighLightKey(item)"></p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { getSearchSuggestApi } from '~/api/search';

const { sensorsRecommendClick } = useSensors();
type Props = {
  keyword: string;
  isFocus?: boolean;
  highLightIdx: number;
  isEasySet?: boolean;
};
type EmitsType = {
  (e: 'emitSuggestData', data: any): void;
  (e: 'emitChangeKeyword', query: any): void;
  // (e: 'emitReportToggleData', bool: boolean): void;
};
const emit = defineEmits<EmitsType>();
const props = defineProps<Props>();
const suggestWord = ref('');
const suggestData = ref([]);
const getSuggest = async () => {
  if (!props.keyword) {
    // if (props.isFocus) {
    //   emit('emitReportToggleData', true);
    // }
    return;
  }
  const { data } = await getSearchSuggestApi({ q: props.keyword, type: props.isEasySet ? 'materials' : '' });
  const res = data?.value?.data ?? [];
  if (data?.value?.code === 0) {
    const idx = res.indexOf(props.keyword);
    if (idx > -1) {
      res.splice(idx, 1);
    }
    suggestData.value = res.slice(0, 6);
    emit('emitSuggestData', res);
  }
  // if (props.isFocus) {
  //   emit('emitReportToggleData', suggestData.value.length !== 0);
  // }
};
const chooseSuggest = (item: any, type: string) => {
  suggestWord.value = item;
  sensorsRecommendClick({
    recommend_type: type,
    recommend_word: item,
  });
  emit('emitChangeKeyword', item);
};

const processHighLightKey = (word: string) => {
  const str = word.replace(props.keyword, `<span class="text-text-tertiary">${props.keyword}</span>`);
  return str;
};
watch(
  () => props.isFocus, // 每次展开的时候重新获取关联词。切换路由时耗材和内容站的关联词不一样
  () => {
    if (props.isFocus) {
      getSuggest();
    }
  },
  { immediate: true },
);

var timer = null as any;
watch(
  () => props.keyword,
  () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(getSuggest, 200);
  },
  { immediate: true },
);
</script>
<style lang="less" scoped>
.suggest {
  .title {
    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
  }
}
.mind {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #f0f0f5;
    }
    &.keyActivity {
      background: #f0f0f5;
    }
  }
  p {
    margin: 0;
    font-size: 14px;
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.pc-show {
  display: block;
}
.xs-show {
  display: none;
}
@media screen and (max-width: 767px) {
  .pc-show {
    display: none;
  }
  .xs-show {
    display: block;
  }
}
</style>

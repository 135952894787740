import { defineStore } from 'pinia';
import type { CommonStateType, ShareModalType } from './types';

export const useCommonStore = defineStore('common', {
  state: (): CommonStateType => ({
    deviceDataList: [],
    isTutorialShow: false,
    shareModalList: {
      name: '',
      id: undefined,
      image: '',
      isShow: false,
    },
    baseXcsData: [],
  }),
  actions: {
    // 向轮训id列表中添加id
    updateDeviceDataList(value: any[]) {
      this.deviceDataList = value;
    },
    updateTutorialShow(value: boolean) {
      this.isTutorialShow = value;
    },
    updateShareModalList(value: ShareModalType) {
      this.shareModalList = value;
    },
    updateBaseXcsData(value: any[]) {
      this.baseXcsData = value;
    },
  },
});

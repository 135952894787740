import { defineStore } from 'pinia';
export const userGuide = defineStore('userGuide', {
  state: () => {
    return {
      isVisible: false,
    };
  },
  actions: {
    show() {
      this.isVisible = true;
    },
    hide() {
      // 关闭
      if (this.isVisible) {
        this.isVisible = false;
      }
    },
  },
});

<template>
  <a-drawer width="85%" placement="left" root-class-name="df-mb-menu" :open="open" @close="onClose">
    <template #closeIcon>
      <icon-font type="icon-close-line" class="text-[24px]" />
    </template>
    <div class="pb-4" @click="onClose">
      <section v-if="isLogin">
        <NuxtLink to="/user-center?source=usercard" class="user flex items-center p-3">
          <img v-lazy="avatar" alt="" class="h-12 w-12 rounded-full object-cover" />
          <div class="flex flex-1 flex-col justify-center gap-2 pl-3 pr-2">
            <span class="text-level-3 font-[500] text-text-secondary">{{ userName }}</span>
            <div v-if="isPrimeCreator" class="h-5 w-[132px]">
              <img class="h-full w-full object-cover" :src="PRIME_CREATOR" alt="" />
            </div>
          </div>
          <icon-font
            type="icon-arrow-down-m"
            :class="['text-[16px] text-text-tertiary transition-all', showMenu ? 'rotate-180' : 'rotate-0']"
            @click.prevent.stop="showMenu = !showMenu"
          />
        </NuxtLink>
        <Menu v-model:open="showMenu" />
      </section>
      <Navs />
      <div class="mt-3 px-2">
        <df-btn v-if="isLogin" type="tertiary" block @click="logout">Logout</df-btn>
        <df-btn v-else type="primary" block @click="login">Login</df-btn>
      </div>
    </div>
  </a-drawer>
</template>

<script setup lang="ts">
import PRIME_CREATOR from 'assets/img/common/premium-creator.png';

import { useUserInfo } from '~/stores/user';
import { useSensors } from '~/composables';
import Menu from './Menu.vue';
import Navs from './Navs.vue';

const route = useRoute();

const open = defineModel('open', {
  type: Boolean,
  default: false,
});
const onClose = () => {
  open.value = false;
};

watch(
  () => route.fullPath,
  () => {
    open.value = false;
  },
);

const showMenu = ref(false);

const userInfoStore = useUserInfo();
const userName = computed(() => userInfoStore.myInfo.name);
const isPrimeCreator = computed(() => userInfoStore.isSaleMaker);
const avatar = computed(() => getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_100,h_100', 'webp'));

const { isLogin } = useLoginStatus();

const { $client } = useNuxtApp();
const { broadcastLogout } = useLoginStatus();
const login = () => {
  $client.login();
};
const logout = async () => {
  try {
    broadcastLogout();
    await $client.logout();
  } finally {
    userInfoStore.resetUserInfo();

    const { sensors }: any = useSensors();
    sensors.logout();
    // 如果在个人中心退出登录自动跳转首页
    if (route.path === '/user-center') {
      window.location.href = '/';
    }
    window.location.reload();
  }
};
</script>

<style lang="less">
.df-mb-menu {
  .menu a {
    display: inline-flex;
    align-items: center;
    padding: 10px 12px;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    line-height: 40px;
    font-size: 14px;
    color: #070b10;

    &:hover {
      background: #494f870d;
    }

    .anticon {
      margin-right: 8px;
      font-size: 20px;
    }
  }
}

.df-mb-menu.ant-drawer {
  .ant-drawer-header {
    padding: 12px 16px;
    border-bottom: none;
  }
  .ant-drawer-close {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    margin-inline-end: 0;
  }
  .ant-drawer-body {
    padding: 0 8px;
    scrollbar-width: none; /* 对于 Firefox 6 */
    &::-webkit-scrollbar {
      display: none; /* 对于 Chrome, Safari 和 Opera */
    }
  }
}
</style>

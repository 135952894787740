<template>
  <div>
    <div class="border-0 border-b border-solid border-[#1A1A1A]/10 px-8 py-4 mobile:border-0 mobile:px-0 mobile:pt-2">
      <img src="../../../assets/img/create.png" alt="" class="w-full rounded-lg" />
      <div class="mt-[10px] text-[14px] leading-[20px] text-text-secondary">
        Templates should include the entire process, from initial design concept, through the making process, to the final presentation of the
        completed product.
      </div>
    </div>
    <div class="flex items-center justify-between px-8 py-4 mobile:flex-col mobile:items-start mobile:px-0 mobile:py-0 mobile:pb-4">
      <a-checkbox v-model:checked="checked" class="text-text-secondary mobile:text-[12px]">Never Show Again</a-checkbox>
      <df-btn type="primary" class="mobile:mt-4 mobile:w-full mobile:text-[12px]" @click="() => emit('goEditor')">Get it</df-btn>
      <div class="w-[154px] mobile:hidden"></div>
    </div>
  </div>
</template>
<script setup lang="ts">
const checked = defineModel('checked', {
  type: Boolean,
  default: true,
});
const emit = defineEmits(['goEditor']);
</script>

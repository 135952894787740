<template>
  <div class="df-share">
    <CreateGuide />
    <a-dropdown @open-change="handleVisibleChange">
      <a class="ant-dropdown-link flex h-[40px]" @click.prevent>
        <df-btn type="highlight" shape="square" @click="onPublishedClick">Publish</df-btn>
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item>
            <a href="javascript:;" class="flex items-center gap-2 py-1 text-[14px]" @click="onPublishedClick">
              <icon-font type="icon-Add" class="text-[20px]" />
              Create New Template
            </a>
          </a-menu-item>
          <a-menu-item>
            <a href="javascript:;" class="flex items-center gap-2 py-1 text-[14px]" @click="goDraft">
              <icon-font type="icon-Draft1" class="text-[20px]" />
              My Draft({{ draftCount }})
            </a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <DraftModal v-model:visible="showDraft" @close="handleClose" />
  </div>
</template>

<script setup lang="ts">
import { getDraftCount } from '@/api/user';
import DraftModal from '@/components/share/create/DraftModal.vue';
import { useCreateEntry } from '@/utils/createHook/hooks';
import { useLayoutStore } from '~/stores/layout';
import { userGuide } from '~/stores/userGuide';
import CreateGuide from './CreateGuide.vue';

const userGuideInfo = userGuide();
const { handleEntry } = useCreateEntry();
const { sensorsMyDraftClickEvent, tracePublishButtonClick } = useSensors();
const onPublishedClick = () => {
  tracePublishButtonClick({
    click_from: 'nav',
    click_element: 'Create New Template',
  });
  handleEntry();
};

const { isLogin } = useLoginStatus();
const layoutStore = useLayoutStore();

const draftCount = ref(0);
const showDraft = ref(false);
type EmitsType = {
  (event: 'update:visible', value: boolean): void;
};
const emits = defineEmits<EmitsType>();
const getNum = async () => {
  if (isLogin.value) {
    const res = await getDraftCount();
    draftCount.value = res.data.value.data.total ? res.data.value.data.total : 0;
  }
};

const { $client } = useNuxtApp();
const goDraft = () => {
  tracePublishButtonClick({
    click_from: 'nav',
    click_element: 'My Draft',
  });

  if (isLogin.value) {
    showDraft.value = true;
  } else {
    $client.login();
  }
  sensorsMyDraftClickEvent();
};
const handleClose = () => {
  layoutStore.initStatus();
  emits('update:visible', false);
};
const handleVisibleChange = (visible: boolean) => {
  if (visible) {
    userGuideInfo.hide(); // 隐藏新手引导
  }
  getNum();
};
onMounted(() => {
  nextTick(getNum);
});
</script>

<style scoped lang="less">
:deep(.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover) {
  background: theme('colors.background.2');
}
</style>

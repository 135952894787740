<template>
  <div ref="elRef" class="df-search-wrap hover:bg-[#fff]">
    <span
      v-show="$route.path !== '/search-result'"
      class="mobile-btn h-10 w-10 rounded-lg transition-all hover:bg-[#494F870D]"
      @click="onSearchClick('/search-result')"
    >
      <icon-font type="icon-search-line" class="cursor-pointer text-[24px]" />
    </span>
    <a-dropdown :open="isFocus" :arrow="false" :get-popup-container="(node: any) => node.parentNode">
      <a-input
        v-show="$route.path !== '/search-result'"
        id="searchInput"
        v-model:value.trim="keyword"
        size="large"
        :placeholder="placeholder"
        autocomplete="off"
        class="df-search border-[#f0f1f5]"
        :class="[{ 'focus-search': showDropdown }]"
        @focus="isFocus = true"
        @blur="blurHandler"
        @press-enter="pressEnter"
        @keydown="changeHighlight"
        @click="onSearchClick"
      >
        <template #prefix>
          <icon-font type="icon-search-line" class="text-[20px] text-[#333333]" />
        </template>
      </a-input>
      <template #overlay>
        <div
          v-show="showDropdown"
          class="rounded-[12px] bg-[#fff] p-0.5"
          :style="{
            width: `480px`,
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 20px 0 rgba(0, 0, 0, 0.1)',
          }"
        >
          <!-- 有搜索词：关联词推荐 -->
          <SearchSuggest
            v-if="keyword"
            :high-light-idx="highLightIdx"
            :is-easy-set="isEasySet"
            :keyword="keyword"
            :is-focus="isFocus"
            @emit-suggest-data="emitSuggestData"
            @emit-change-keyword="chooseKeyword"
          />
          <!-- 无搜索词：推荐面板 -->
          <SearchSelect
            v-if="!keyword && !isEasySet"
            :high-light-idx="highLightIdx"
            :keyword="keyword"
            :is-focus="isFocus"
            @emit-suggest-data="emitSuggestData"
            @emit-change-keyword="chooseKeyword"
            @emit-toggle-dropdown="toggleDropdown"
          />
        </div>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup lang="ts">
import { useUserInfo } from '@/stores/user';
import { TabMap } from '~/components/search-result/ts/const';

const { isLogin } = useLoginStatus();
const userInfoStore = useUserInfo();

const isFocus = ref(false);
const keyword = ref('');
const elRef = ref<HTMLElement>();

const highLightIdx = ref(-1);
const changeHighlight = (e: any) => {
  if (keyword.value) {
    if (e.keyCode === 40 && highLightIdx.value < suggestData.value.length - 1) {
      highLightIdx.value = highLightIdx.value + 1;
    }
    if (e.keyCode === 38 && highLightIdx.value > -1) {
      highLightIdx.value = highLightIdx.value - 1;
    }
  }
};
const suggestData = ref([]);
const emitSuggestData = (data: any) => {
  suggestData.value = data;
};
const chooseKeyword = (query: string) => {
  keyword.value = query;
  go2SearchResultPage();
};
const reset = () => {
  setTimeout(() => {
    keyword.value = '';
  }, 200);
};

const pressEnter = () => {
  if (!keyword.value) {
    return;
  }
  if (highLightIdx.value > -1) {
    keyword.value = suggestData.value[highLightIdx.value] as any;
    highLightIdx.value = -1;
  }
  isFocus.value = false; // 关闭下拉弹窗
  go2SearchResultPage();
};
const { sensorSearchClick, traceNavClick } = useSensors();
const router = useRouter();
const go2SearchResultPage = () => {
  keyword.value = keyword.value?.trim();
  try {
    const { cid } = useDeviceFlags();
    sensorSearchClick({
      keyword: keyword.value,
      entry: '头部',
      anonymous_id: (isLogin.value ? userInfoStore.myInfo.id : cid.value) as string | number,
    });
  } finally {
    const type = isEasySet.value ? TabMap.MATERIALS : TabMap.ASSET;
    router.push(`/search-result?q=${encodeURIComponent(keyword.value)}&type=${type}`);
  }
  reset();
};

// 控制面板收起展开
const toggleDropdown = (focusBool: boolean) => {
  isFocus.value = focusBool;
};

// 失焦时 只隐藏搜索关联词下拉框
const blurHandler = () => {
  if (keyword.value.length > 0) {
    isFocus.value = false;
  }
};

// 区分耗材和内容站 ：是否显示下拉框
const showDropdown = computed(() => {
  if (isEasySet.value) {
    return isFocus.value && keyword.value.length > 0;
  }
  return isFocus.value;
});
const route = useRoute();
const isEasySet = computed(() => {
  return route.path.includes('/easyset');
});
const placeholder = computed(() => {
  return isEasySet.value ? 'Search for material name or code' : 'Search for...';
});

const onSearchClick = (path?: string) => {
  traceNavClick({
    element_name: 'search',
  });

  if (path) {
    navigateTo(path);
  }
};
</script>

<style scoped lang="less">
.df-search-wrap {
  @media (min-width: 1028px) {
    max-width: 480px;
  }
  @media (max-width: 1027px) {
    //max-width: 40px;
  }

  .df-search {
    border-radius: 8px;
    background: theme('colors.newBg.surface');
    box-shadow: none;
    &:hover {
      border-color: transparent;
      background:
        linear-gradient(0deg, var(--color-bg-hover, rgba(73, 79, 135, 0.05)) 0%, var(--color-bg-hover, rgba(73, 79, 135, 0.05)) 100%),
        var(--color-bg-surface, #f0f0f5);
    }
    &.focus-search {
      border-color: theme('colors.blue.default');
      background: #fff;
    }
    :deep(.ant-input) {
      background: transparent;
    }
    :deep(.ant-input-prefix) {
      margin-right: 8px;
    }
    @media (max-width: 1027px) {
      display: none;
    }
  }
  .mobile-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1028px) {
      display: none;
    }
  }
}
</style>

<template>
  <div
    ref="elRef"
    :class="[`df-menu-item`, activeKey === name ? 'active' : '']"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
    @click.stop="onToggle"
  >
    <div v-if="!isNil(slots.icon)">
      <slot name="icon"></slot>
    </div>
    <a :href="to" class="title" @click.prevent="">
      <slot>{{ title || name }}</slot>
    </a>
    <template v-if="!isNil(slots.detail)">
      <NuxtIcon name="df:arrow-down-m" class="ml-1 text-[14px]" />
      <Content />
    </template>
  </div>
</template>

<script setup lang="ts">
import { isNil } from 'lodash-es';
import { renderSlot, type VNode } from 'vue';
import { DF_MENU_CTX_KEY, type DfMenuCtx, type DfMenuItemInstance } from './types';

const props = defineProps({
  type: {
    type: String as PropType<'drawer' | 'dropdown'>,
    default: 'drawer',
  },
  to: { type: String, default: '' },
  name: { type: String, default: '' },
  title: { type: String, default: '' },
  offset: { type: Number, default: 14 },
});

const slots = defineSlots<{
  default: () => VNode[];
  icon: () => VNode[];
  detail: () => VNode[];
}>();

const { activeKey, openKey, items, timeout } = inject(DF_MENU_CTX_KEY, null as unknown as DfMenuCtx);

const elRef = shallowRef<HTMLElement>();
const item = shallowReactive<DfMenuItemInstance>({
  get el() {
    return elRef.value;
  },
  get type() {
    return props.type;
  },
  get offset() {
    return props.offset;
  },
});
const Content = () => {
  if (!isNil(slots.detail)) {
    item.content = renderSlot(slots, 'detail');
  }
  return [];
};

const onMouseenter = () => {
  if (openKey.value === '') {
    timeout.exec(() => {
      openKey.value = props.name;
    }, 200);
  } else {
    openKey.value = props.name;
  }
};
const onMouseleave = () => {
  if (openKey.value === '') {
    timeout.cancel();
  }
};

const onToggle = () => {
  timeout.cancel();
  if (activeKey.value !== props.name) {
    activeKey.value = props.name;
    return;
  }
  if (openKey.value === props.name) {
    openKey.value = '';
  } else {
    openKey.value = props.name;
  }
};

items[props.name] = item;
onBeforeUnmount(() => {
  delete items[props.name];
});
</script>

<style scoped lang="less">
.df-menu-item {
  display: inline-flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  font-size: 0;
  cursor: pointer;
  transition: all 0.2s ease-in;

  a {
    font-size: 14px;
    font-style: normal;
    font-weight: theme('fontWeight.semibold');
    line-height: 20px;
    color: #575757;
  }

  &:hover {
    background: #f0f1f5;
    a {
      color: #1a1a1a;
    }
  }
}
</style>

<template>
  <div>
    <div v-if="!isMobile">
      <a-modal
        destroy-on-close
        :width="800"
        :open="visible"
        :z-index="zIndex"
        :footer="null"
        class="create-tips-modal"
        closable
        title="How to create a good template"
        @cancel="handleClose"
      >
        <Content v-model:checked="checked" @go-editor="handleClick" />
      </a-modal>
    </div>
    <div v-else>
      <a-drawer
        width="100%"
        height="unset"
        placement="bottom"
        destroy-on-close
        :open="visible"
        :closable="false"
        :z-index="zIndex"
        class="create-mb-drawer"
        dialog-class="create-mb-drawer"
        @close="handleClose"
      >
        <div class="flex justify-between py-3">
          <div class="text-[16px] font-bold">How to create a good template</div>
          <icon-font type="icon-close-line" class="text-[20px]" @click="handleClose" />
        </div>
        <Content v-model:checked="checked" @go-editor="handleClick" />
      </a-drawer>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getDraftCount } from '@/api/user';
import { useLayoutStore } from '@/stores/layout';
import { useCreateEntry } from '@/utils/createHook/hooks';
import Content from './CreateTips.vue';
const { isMobile } = useScreen();
const { goEditorRoute } = useCreateEntry();
const { sensorsMakingOptionExposure, sensorsMakingOptionClickEvent } = useSensors();

type PropType = {
  visible: boolean;
  zIndex?: number;
};
const props = withDefaults(defineProps<PropType>(), {
  zIndex: 1005,
});
type EmitsType = {
  (event: 'update:visible', value: boolean): void;
};
const emits = defineEmits<EmitsType>();
const layoutStore = useLayoutStore();

watch(
  () => props.visible,
  (val) => {
    if (val) {
      getNum();
      sensorsMakingOptionExposure();
    }
  },
);
const handleClose = () => {
  sensorsMakingOptionClickEvent({
    click_element: 'close',
    never_show: checked.value,
  });
  layoutStore.initStatus();
  checked.value = true;

  emits('update:visible', false);
};
const checked = ref(true);
const draftCount = ref(0);
const getNum = async () => {
  const res = await getDraftCount();
  draftCount.value = res.data.value.data.total ? res.data.value.data.total : 0;
};

const handleClick = () => {
  if (checked.value) {
    const createTipsModal = {
      desc: '不再提醒创建提示弹窗',
      value: true,
    };
    window.localStorage.setItem('createTipsModal', JSON.stringify(createTipsModal));
  }
  sensorsMakingOptionClickEvent({
    click_element: 'get',
    never_show: checked.value,
  });

  checked.value = true;
  emits('update:visible', false);
  goEditorRoute();
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 992px) {
  :deep(.ant-card-body) {
    font-size: 12px;
  }
  .link-list .link-box {
    padding: 2px 50px 2px 20px;
  }
}
</style>
<style lang="less">
.create-tips-modal {
  &.ant-modal {
    .ant-modal-header {
      border-bottom: 1px solid rgba(26, 26, 26, 0.1);
      padding: 18px 32px;
      margin: 0;
    }
    .ant-modal-body {
      padding: 0px;
    }
  }
  .ant-card-body {
    padding: 0;
  }
}
.create-mb-drawer {
  z-index: 1005;
  &.ant-drawer-content {
    border-radius: 16px 16px 0 0;
    .ant-drawer-body {
      padding: 0 16px;
    }
  }
}
</style>

<template>
  <div class="main-content">
    <div class="main" :class="{ 'un-need-bg': !isNeedBg }">
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
const route = useRoute();

const isNeedBg = ref(true);
const getBgFn = () => {
  if (
    route.fullPath?.includes('/easyset/studio-material-list?openFrom=studioEditor') ||
    route.fullPath?.includes('/studio/iframe/studio-material-list?openFrom=studioEditor')
  ) {
    isNeedBg.value = false; // 如果是从Studio编辑器打开的，material 弹窗 就不需要背景了
  }
};
onMounted(() => {
  getBgFn();
});
</script>
<style lang="less" scoped>
// .main-content {
//   min-width: 1200px;
// }
.main {
  min-height: calc(100vh - 232px);
  background: #fff;
}
.un-need-bg {
  background: transparent;
}
// 移动端适配 start
@media screen and (max-width: 767px) {
  .main-content {
    width: 100%;
    min-width: 375px;
  }
  .main {
    min-height: calc(100vh - 491px);
  }
}
// end
</style>

<template>
  <div class="nav-project-detail flex w-full">
    <div v-if="!isNil(subs) && !isEmpty(subs)" class="left pt-4">
      <div class="grid grid-cols-4">
        <div v-for="subItem in subs" :key="subItem.label" class="catalog-item flex flex-shrink-0 flex-grow-0 flex-col">
          <h5 class="nav-ellipsis w-full px-[12px] text-level-2 font-bold text-text-primary">{{ subItem.label }}</h5>
          <div class="flex flex-col">
            <a
              v-for="(item, index) in subItem.items"
              :key="index"
              :href="item.path"
              :class="[
                'nav-ellipsis',
                'h-[36px] w-fit max-w-full px-[12px] py-[8px]',
                'text-sm font-normal leading-tight text-text-secondary',
                'hover:rounded-[6px] hover:bg-background-3 hover:text-text-primary',
                'transition-all ease-in-out',
              ]"
              @click.prevent="myNavigateTo(item.path, `${navKey}_${item.label}`)"
            >
              {{ item.label }}
            </a>
          </div>
        </div>
      </div>
      <NuxtLink
        to="/all?types=project&sortType=recommend&page=1"
        class="mt-2 inline-flex h-10 items-center pl-3 font-semibold text-[#71717A]"
        @click.prevent="myNavigateTo('/all?types=project&sortType=recommend&page=1')"
      >
        <span class="text-level-4 text-text-primary">View all Templates</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            :d="'M9.29137 16.686C8.90169 16.2947 8.90305 15.6615 9.29442 15.2718L12.5827 11.9977L9.29442 8.72352C8.90305 8.33384 8.90169 7.70068 9.29137 7.30931C9.68105 6.91795 10.3142 6.91658 10.7056 7.30626L14.7056 11.2891C14.8941 11.4767 15 11.7317 15 11.9977C15 12.2636 14.8941 12.5187 14.7056 12.7063L10.7056 16.6891C10.3142 17.0788 9.68105 17.0774 9.29137 16.686Z'"
            fill="currentColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
          />
        </svg>
      </NuxtLink>
    </div>
    <div v-if="!isNil(imgInfo) && !isEmpty(imgInfo.image)" class="right flex justify-center pt-4">
      <a class="all-card rounded-xl text-text-primary hover:text-text-primary" :href="link" @click.prevent="onClick">
        <div class="cover w-full p-[8px]">
          <div class="relative h-auto w-full overflow-hidden rounded-[7.24px] pt-[52.63%]">
            <img :src="imgInfo.image" alt="" class="absolute left-0 top-0 h-full w-full cursor-pointer object-cover" />
          </div>
        </div>
        <div class="content px-[16px] pb-[16px] pt-[8px]">
          <h5 class="truncate break-all text-level-2 font-bold">{{ imgInfo.title }}</h5>
          <p class="desc mb-[28px] text-level-5">
            {{ imgInfo.desc }}
          </p>
          <ClientOnly>
            <df-btn type="primary" size="small">View</df-btn>
          </ClientOnly>
        </div>
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { isEmpty, isNil } from 'lodash-es';
import type { PropType } from 'vue';
import { useNav, type Nav } from '../hooks';
import { getSameSiteURLPath } from '~/utils/url';

const props = defineProps({
  nav: {
    type: Object as PropType<Nav>,
    default: () => ({}),
  },
});

const route = useRoute();

const { myNavigateTo } = useNav();

const navKey = computed(() => props.nav.key);
const subs = computed(() => props.nav.subs);
const imgInfo = computed(() => props.nav.imgInfo);

const defuLink = '/all?types=project&sortType=recommend&page=1';
const link = ref(imgInfo?.link ?? defuLink);
const onClick = () => {
  if (link.value === defuLink) {
    myNavigateTo(link.value);
    return;
  }
  if (isSameSiteURL(link.value)) {
    const path = getSameSiteURLPath(link.value);
    if (route.fullPath !== path) {
      myNavigateTo(path);
    }
  } else {
    window.open(link.value, '_blank');
  }
};
</script>

<style scoped lang="less">
.nav-project-detail {
  @media (max-width: 992px) {
    flex-direction: column;
  }
  .left {
    width: 67.23%;
    .catalog-item {
      padding-right: 16px;
    }
    @media (max-width: 992px) {
      width: 100%;
      .catalog-item:last-child {
        padding-right: 0;
      }
    }
  }
  .right {
    flex: 1;

    @media (max-width: 992px) {
      flex: none;
      width: 100%;
    }
    .all-card {
      flex-direction: column;
      box-shadow: 0 0 20px 0 #0000001f;

      .desc {
        overflow: hidden;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webki-box-orient: vertical;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      @media (max-width: 992px) {
        flex-direction: row;
      }

      @media (min-width: 1200px) {
        width: 396px;
      }
      @media (max-width: 1199px) {
        width: 100%;
      }
      @media (max-width: 992px) {
        display: flex;
        .cover {
          flex-shrink: 0;
          width: 304px;
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }
    }
  }
}
.nav-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<template>
  <a v-if="isShow" :href="link" target="_blank">
    <df-btn type="tertiary" shape="square" @click="onClick">Studio</df-btn>
  </a>
</template>

<script setup lang="ts">
const link = computed(() => {
  if (getUToken()) {
    return import.meta.env.VITE_ATOMM_STUDIO_SITE;
  } else {
    return `${import.meta.env.VITE_PUBLIC_SITE_URL}/studio`;
  }
});

const isShow = ref(false);
onMounted(() => {
  isShow.value = !isInApp();
});

const { traceNavClick } = useSensors();
const onClick = () => {
  traceNavClick({
    element_name: 'studio',
  });
  console.log('[studio-btn]', link.value);
};
</script>

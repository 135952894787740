<template>
  <header class="mobile-header bg-[#FFF] px-4 py-3">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <div class="h-10 w-10 cursor-pointer rounded-lg p-2 transition-all hover:bg-[#494F870D]" @click="openMenu">
          <icon-font type="icon-menu" class="text-[24px]" />
          <Drawer v-model:open="open" />
        </div>
        <NuxtLink to="/" class="inline-flex items-center">
          <Logo class="text-[115px]" />
        </NuxtLink>
      </div>
      <ClientOnly>
        <div class="flex items-center">
          <div v-if="isShowSearch" class="h-10 w-10 rounded-lg p-2 transition-all hover:bg-[#494F870D]" @click="search">
            <icon-font type="icon-search-line" class="text-[24px]" />
          </div>
          <div v-if="isLogin" class="message h-10 w-10 rounded-lg p-2 transition-all hover:bg-[#494F870D]" @click="openAllMessage">
            <a-badge :count="unreadCount" :overflow-count="99">
              <icon-font type="icon-message-line" class="text-[24px]" />
            </a-badge>
          </div>
          <div class="h-10 w-10 rounded-lg p-2 transition-all hover:bg-[#494F870D]" @click="openMbEditorEntryModal">
            <icon-font type="icon-a-ShareCreationh5" class="text-[24px]" />
          </div>
        </div>
      </ClientOnly>
    </div>
    <ClientOnly>
      <MbEntryModal v-model:visible="layoutStore.isShowMbEditorEntry" />
      <CommonActCreateMbModal v-model:visible="layoutStore.isActMbModalShow" />
    </ClientOnly>
  </header>
</template>
<script setup lang="ts">
import { useLayoutStore } from '~/stores/layout';
import Drawer from './Drawer.vue';
import MbEntryModal from '~/components/share/create/MbEntryModal.vue';

const router = useRouter();

const { $client, $messageClient } = useNuxtApp();
const { isLogin } = useLoginStatus();

const { traceNavClick, tracePublishButtonClick } = useSensors();

const open = ref(false);
const openMenu = () => {
  open.value = true;
};

const isShowSearch = computed(() => router.currentRoute.value.path !== '/search-result');
const search = () => {
  traceNavClick({
    element_name: 'search',
  });

  window.open(`/search-result`);
};

const unreadCount = useState('message-unread-count', () => 0);
const openAllMessage = () => {
  traceNavClick({
    element_name: 'notification',
  });

  $messageClient.openAllMessage();
};

const layoutStore = useLayoutStore();
const openMbEditorEntryModal = () => {
  tracePublishButtonClick({
    click_from: 'nav',
    click_element: 'Create New Template',
  });

  if (isLogin.value) {
    layoutStore.openMbEditorEntryModal();
    return;
  }
  $client.login();
};
</script>

<style scoped lang="less">
.mobile-header {
  display: none;

  @media (max-width: 860px) {
    display: block;
  }
}

.message {
  :deep(.ant-badge) {
    .ant-badge-count {
      height: 16px;
      min-width: 16px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 16px;
      box-shadow: 0 0 0 2px #fff;

      &.ant-badge-multiple-words {
        padding: 0 4px;
      }
    }

    .ant-scroll-number-only {
      height: 16px;
      font-size: 12px;
      line-height: 16px;
      .ant-scroll-number-only-unit {
        height: 16px;
      }
    }
  }
}
</style>

<template>
  <a-card class="draft-card relative cursor-pointer" @click.stop="handleDraftItemClick(draftItem)">
    <img v-if="draftItem.forSale" class="absolute -left-2 top-4 w-[85px]" :src="PremiumSvg" alt="" />
    <template #cover>
      <div class="draft-content relative h-[200px] overflow-hidden rounded-lg bg-background-2">
        <div class="cover-section" :class="draftItem.cover ? '' : 'fit-height'">
          <div v-if="draftItem.cover && draftItem.cover.length > 0">
            <a-image :src="getOssProcessedImage(draftItem.cover, 'm_fill,h_240,w_320', 'webp')" :preview="false" />
          </div>
          <icon-font v-else type="icon-Templates " class="mt-[70px] text-[64px] text-background-4" />
        </div>
        <div
          v-if="draftItem.aiImages && draftItem.aiImages.length > 0"
          class="absolute bottom-2 right-2 h-[64px] w-[64px] rounded bg-[#fff] p-1 shadow-sm"
        >
          <img :src="getOssProcessedImage(draftItem.aiImages[0], 'm_mfit,h_60,w_60', 'webp')" alt="" class="w-full" />
          <img :src="AiIcon" class="ai-icon absolute bottom-1 right-1 h-7 w-7" />
        </div>

        <div class="draft-mask absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center gap-6 rounded-lg bg-uni-7/40">
          <div class="cursor-pointer rounded-lg bg-uni-6">
            <icon-font type="icon-edit-box" class="p-1 text-[32px]" />
          </div>

          <div class="absolute right-2 top-2 cursor-pointer rounded-lg bg-red-default text-uni-6" @click.stop="handleDeleteDraft(draftItem.id)">
            <icon-font type="icon-Delete" class="p-1 text-[20px]" />
          </div>
        </div>
      </div>
    </template>

    <a-card-meta>
      <template #title>
        <span class="card-title font-semibold">
          {{ draftItem.title ? draftItem.title : 'No Title' }}
        </span>
      </template>

      <template #description>
        <a-row justify="space-between">
          <a-col>
            <span>{{ ShareType[draftItem.type] }}</span>
            |
            <span class="draft-info">
              {{ moment(draftItem.updatedAt * 1000).format('YYYY/MM/DD') }}
            </span>
          </a-col>
        </a-row>
      </template>
    </a-card-meta>
  </a-card>
</template>

<script lang="ts" setup>
import AiIcon from '@/assets/img/ai-icon.png';
import type { ItemType } from '@/types/common';
import { Modal } from 'ant-design-vue';
import moment from 'moment';
import { useRouter } from 'vue-router';
import PremiumSvg from '~/assets/img/prime2.png';
const router = useRouter();
type PropsType = {
  draftItem: ItemType;
  hasAction?: boolean;
};
type EmitsType = {
  (event: 'deleteDraft', id: number): void;
  (event: 'close'): void;
};

const ShareType = {
  'project': 'Template',
  'bundle': 'Bundle',
  'xthings': 'xthings',
  'designs': 'Design',
} as any;
const props = withDefaults(defineProps<PropsType>(), {
  hasAction: true,
});
const emit = defineEmits<EmitsType>();

const type = computed(() => {
  return props.draftItem?.type;
});

const { sensorDraftItemClick } = useSensors();
const handleDraftItemClick = (item: ItemType) => {
  if (type.value === 'xthings') {
    router.push({
      path: `/edit/xthings/${item.id}`,
    });
  } else {
    router.push({
      path: `/share/${item.type}`,
      query: {
        id: item.id,
      },
    });
  }
  sensorDraftItemClick({
    content_id: item.id, // 内容id
    content_name: item.title, // 内容名称
    content_tag: item.tags as string[], // 内容标签
    publish_time: item.publishedAt, // 发布时间
    author_name: item.createdByName, // 作者名称
    author_id: item.createdBy, // 作者id
    service_type: item.type,
  });
  emit('close');
};
const handleDeleteDraft = (id: number) => {
  Modal.confirm({
    content: 'Are you sure you want to delete?',
    okText: 'Delete',
    zIndex: 1011,
    onOk: () => {
      emit('deleteDraft', id);
    },
  });
};
</script>

<style lang="less" scoped>
.draft-card {
  position: relative;
  background: #ffffff;
  border-color: rgba(0, 0, 0, 0.04) !important;
  .draft-mask {
    display: none;
  }
  &:hover {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

    .draft-mask {
      display: flex;
    }
  }
  :deep(.ant-card-cover) {
    // height: 200px;
    overflow: hidden;
    padding: 8px;
    .ai-icon {
      border-radius: 0;
    }
    // background-color: #f5f7fb;
  }
  :deep(.ant-card-meta-title) {
    margin-bottom: 6px !important;
  }
  .type-comb {
    position: absolute;
    left: 16px;
    top: 16px;
    color: #000000;
    .xthings-tag {
      display: none;
    }
    &.xthings {
      .type-tag {
        display: none;
      }
      .xthings-tag {
        display: inline-block;
        width: auto;
      }
    }
  }
  .type-tag {
    display: inline-block;
    width: auto;
    padding: 0 16px;
    background: #ffffff;
    font-size: 14px;
    border-radius: 4px;
    height: 24px;
    line-height: 24px;
  }
  .for-free {
    font-size: 12px;
    margin-left: 5px;
    font-weight: theme('fontWeight.semibold');
    background: linear-gradient(121deg, #ffe2b7 7.87%, #e2a054 45.13%, #ffbb6b 83.55%, #ffd66b 96.91%);
  }
  .op-icon {
    position: absolute;
    font-size: 20px;
    width: 32px;
    right: 16px;
    top: 16px;
    border-radius: 2px;
    padding: 1px 5px;
    background: rgba(33, 33, 33, 0.8);
    color: #fff;
  }
  .cover-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .draft-info {
    font-size: 12px;
    line-height: 16px;
    color: #9e9e9e;
  }
  img.default-cover {
    // width: 160px;
    height: 164px;
    object-fit: cover;
  }

  .card-title {
    font-size: 14px;
    line-height: 20px;
  }

  :deep(.ant-card-body) {
    padding: 8px 8px 16px;
  }
}

.op-link {
  width: 100%;
  display: block;
}

.op-btn {
  color: black;
  width: 100%;
  &:hover {
    background: #f8f8f8;
    color: black;
  }
}
.op-btn-red {
  color: #ff0035;
  &:hover {
    color: #ff0035;
  }
}
</style>

<style lang="less">
.op-overlay {
  .ant-popover-content {
    .ant-popover-inner-content {
      padding: 2px;
    }
  }
}
@media screen and(max-width: 767px) {
  .cover-section {
    .ant-image {
      width: 100%;
    }
    :deep(.ant-image-img) {
      object-fit: cover;
    }
  }
}
</style>
